import { useState, useEffect } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
// import { Language as LanguageIcon } from "@mui/icons-material";
import { get, map } from "lodash";
import { withTranslation } from "react-i18next";
import { Globe as LanguageIcon } from "react-feather";
import { useTheme } from "@mui/material/styles";

//i18n
import i18n from "../../i18n";
import languages from "../../common/languages";

const LanguageDropdown = () => {
  const theme = useTheme();
  const [selectedLang, setSelectedLang] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
    setSelectedLang(currentLanguage);
  }, []);

  const changeLanguageAction = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    setSelectedLang(lang);
    setAnchorEl(null);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={handleMenuOpen}
        size="small"
        sx={{ color: theme.palette.para.main, marginRight: "-10px" }}
      >
        <LanguageIcon size={22} strokeWidth={1.5} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {map(Object.keys(languages), (key) => (
          <MenuItem
            key={key}
            onClick={() => changeLanguageAction(key)}
            sx={{
              fontWeight: selectedLang === key ? "bold" : "regular",
              display: "flex",
              gap: "10px",
            }}
          >
            <img
              src={get(languages, `${key}.flag`)}
              alt="Header Language"
              className="me-1"
              height="12"
            />
            <span>{get(languages, `${key}.label`)}</span>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default withTranslation()(LanguageDropdown);

// import React, { useEffect, useState } from "react";
// import {
//   Dropdown,
//   DropdownItem,
//   DropdownMenu,
//   DropdownToggle,
// } from "reactstrap";
// import { get, map } from "lodash";
// import { withTranslation } from "react-i18next";

// //i18n
// import i18n from "../../i18n";
// import languages from "../../common/languages";

// const LanguageDropdown = () => {
//   // Declare a new state variable, which we'll call "menu"
//   const [selectedLang, setSelectedLang] = useState("");
//   const [menu, setMenu] = useState(false);

//   useEffect(() => {
//     const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
//     setSelectedLang(currentLanguage);
//   }, []);

//   const changeLanguageAction = (lang) => {
//     //set language as i18n
//     i18n.changeLanguage(lang);
//     localStorage.setItem("I18N_LANGUAGE", lang);
//     setSelectedLang(lang);
//   };

//   const toggle = () => {
//     setMenu(!menu);
//   };

//   return (
//     <>
//       <Dropdown
//         isOpen={menu}
//         toggle={toggle}
//         className="d-none d-lg-inline-block ms-2"
//       >
//         <DropdownToggle className="btn header-item waves-effect" tag="button">
//           <img
//             src={get(languages, `${selectedLang}.flag`)}
//             alt="Header Language"
//             height="16"
//             className="me-2"
//           />{" "}
//           {get(languages, `${selectedLang}.label`)}{" "}
//           <span className="mdi mdi-chevron-down"></span>
//         </DropdownToggle>
//         <DropdownMenu className="language-switch dropdown-menu-end">
//           {map(Object.keys(languages), (key) => (
//             <DropdownItem
//               key={key}
//               onClick={() => changeLanguageAction(key)}
//               className={`notify-item ${
//                 selectedLang === key ? "active" : "none"
//               }`}
//             >
//               <img
//                 src={get(languages, `${key}.flag`)}
//                 alt="Header Language"
//                 className="me-1"
//                 height="12"
//               />
//               <span className="align-middle">
//                 {get(languages, `${key}.label`)}
//               </span>
//             </DropdownItem>
//           ))}
//         </DropdownMenu>
//       </Dropdown>
//     </>
//   );
// };

// export default withTranslation()(LanguageDropdown);
