import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { api } from "../axios/api";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import AddCustomerForm from "./AddCustomerForm";
import { Edit, Trash2 } from "react-feather";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation } from "react-i18next";

const ShowCustomers = (props) => {
  const theme = useTheme();
  const [contacts, setContacts] = useState([]);
  const user = useSelector((state) => state.user);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editOptions, setEditOptions] = useState({});
  const [filteredContacts, setFilteredContacts] = useState([]);

  useEffect(() => {
    let body = {
      company_id: user?.company_id,
    };
    if (user?.company_id) {
      api
        .post("/getCustomers", body)
        .then((res) => {
          console.log("response from getting contacts", res?.data);
          setContacts(res?.data);
          setFilteredContacts(res?.data);
          setSearchingField("trade_name");
          setSearchingTerm("");
        })
        .catch((err) => console.log(""));
    }
    if (!showAddForm) {
      setEditOptions({});
    }
  }, [user, showAddForm]);

  const [sorting, setSorting] = useState({
    field: "id", // default sorting field
    order: "asc", // default sorting order
  });

  /*******searching */
  const [searchingField, setSearchingField] = useState("trade_name");
  const [searchingTerm, setSearchingTerm] = useState("");

  const handleSearch = (searchedterm) => {
    setSearchingTerm(searchedterm);
  };
  useEffect(() => {
    if (searchingTerm?.length > 0) {
      let filtered = contacts?.filter((c) =>
        c[searchingField]?.toLowerCase()?.includes(searchingTerm?.toLowerCase())
      );
      setFilteredContacts(filtered);
    } else {
      setFilteredContacts(contacts);
    }
  }, [searchingTerm, searchingField, contacts]);
  return (
    <Box sx={{ padding: "20px 30px" }}>
      <ToastContainer />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.heading.main,
            fontSize: "1.3rem",
            fontWeight: "600",
          }}
        >
          {props.t("Customers")}
        </Typography>
        <Button
          variant="contained"
          onClick={() => setShowAddForm(!showAddForm)}
          sx={{
            backgroundColor: theme.palette.brandColorOne.main,
            color: "#fff",
          }}
          size="small"
        >
          {showAddForm ? props.t("View Customers") : props.t("Add Customer")}
        </Button>
      </Box>

      {showAddForm ? (
        <AddCustomerForm
          editOptions={editOptions}
          setShowAddForm={setShowAddForm}
        />
      ) : (
          <Box
              sx={{
                  marginTop: "20px",
                  backgroundColor: theme.palette.tableBg.main,
              }}
          >
              <Box
                  sx={{
                      my: 2,

                      p: 3,
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "flex-end",
                  }}
              >
                  <Box
                      sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "transparent",
                          borderRadius: "9999px",
                          overflow: "hidden",
                          boxShadow: `inset 0px 0px 0px 1px ${theme.palette.secondary.main}`,
                          "&:hover": {
                              boxShadow: `inset 0px 0px 0px 2px ${theme.palette.secondary.main}`,
                          },
                          padding: "0 15px",
                      }}
                  >
                      <SearchIcon
                          sx={{
                              color: theme.palette.para.main,
                              p: "10",
                              flexGrow: 0,
                          }}
                      />
                      <InputBase
                          placeholder={props?.t("Search")}
                          value={searchingTerm}
                          onChange={(e) => handleSearch(e.target.value)}
                          sx={{
                              flexGrow: 1,
                              "& input": {
                                  padding: "10px",
                                  color: theme.palette.heading.main,
                              },
                          }}
                          inputProps={{"aria-label": "search"}}
                      />
                  </Box>
                  <Typography>{props?.t("by")}</Typography>
                  <Box>
                      <FormControl fullWidth>
                          <Select
                              sx={{height: "40px"}}
                              value={searchingField}
                              onChange={(e) => {
                                  setSearchingField(e.target.value);
                              }}
                          >
                              <MenuItem value="trade_name">
                                  {props?.t("Trade Name")}
                              </MenuItem>
                              <MenuItem value="street_and_house_no">
                                  {props?.t("Street and House-No.")}
                              </MenuItem>
                              <MenuItem value="city">{props?.t("Town/City")}</MenuItem>
                              <MenuItem value="country">{props?.t("Country")}</MenuItem>
                          </Select>
                      </FormControl>
                  </Box>
              </Box>

              {searchingTerm?.length > 0 && (
                  <Box
                      sx={{
                          px: 2,
                          mx: 2,
                          mb: 2,
                          width: "max-content",
                          borderRadius: "2px",
                      }}
                  >
                      <Typography
                          variant="p"
                          sx={{color: theme.palette.primary.main}}
                      >
                          {props?.t("Filtered rows")}: {filteredContacts?.length} /{" "}
                          {contacts?.length}
                      </Typography>{" "}
                  </Box>
              )}
              <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12}>
                      <TableContainer component={Paper}>
                          <Table sx={{backgroundColor: theme.palette.tableBg.main}}>
                              <TableHead>
                                  <TableRow>
                                      <TableCell>{props?.t("ID")}</TableCell>

                                      <TableCell>{props?.t("Trade Name")}</TableCell>
                                      <TableCell>
                                          {props?.t("Street and House-No.")}
                                      </TableCell>
                                      <TableCell>{props?.t("Zip Code")}</TableCell>
                                      <TableCell>{props?.t("Town/City")}</TableCell>
                                      <TableCell>{props?.t("Country")}</TableCell>
                                      <TableCell>{props?.t("Shipments")}</TableCell>
                                      <TableCell>{props?.t("Actions")}</TableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                  {filteredContacts?.map((contact, i) => (
                                      <Customer
                                          key={contact.id}
                                          contact={contact}
                                          i={i}
                                          contacts={contacts}
                                          setContacts={setContacts}
                                          setShowAddForm={setShowAddForm}
                                          setEditOptions={setEditOptions}
                                          setFilteredContacts={setFilteredContacts}
                                      />
                                  ))}
                              </TableBody>
                          </Table>
                      </TableContainer>
                  </Grid>
              </Grid>
          </Box>
      )}
    </Box>
  );
};

const Customer = ({
  contact,
  i,
  contacts,
  setContacts,
  setEditOptions,
  setShowAddForm,
  setFilteredContacts,
}) => {
  const [totalShipments, setTotalShipments] = useState(0);
  const theme = useTheme();
  useEffect(() => {
    api
      .get("/getOutgoingGoodsByCustomerId/" + contact?.id)
      .then((res) => {
        setTotalShipments(res?.data?.length);
      })
      .catch((err) => {
        console.log("");
        setTotalShipments(0);
      });
  }, [contact?.id]);

  const deleteCustomer = () => {
    api
      .delete("/deleteCustomer/" + contact?.id)
      .then((res) => {
        toast.success("Deleted Successfully!");
        setFilteredContacts(contacts?.filter((c) => c?.id !== contact?.id));
      })
      .catch((err) => {
        console.log("");
        toast.error("unable to delete!");
      });
  };

  const editSupplier = () => {
    setEditOptions(contact);
    setShowAddForm(true);
  };
  return (
    <TableRow key={contact?.id}>
      <TableCell>{contact?.id}</TableCell>

      <TableCell>{contact?.trade_name}</TableCell>
      <TableCell>{contact?.street_and_house_no}</TableCell>
      <TableCell>{contact?.zip_code}</TableCell>
      <TableCell>{contact?.city}</TableCell>
      <TableCell>{contact?.country}</TableCell>
      <TableCell>{totalShipments}</TableCell>
      <TableCell>
        <Box sx={{ display: "flex", gap: "5px" }}>
          <IconButton onClick={() => editSupplier()}>
            <Edit size="18" color={theme.palette.brandColorOne.main} />
          </IconButton>
          <IconButton
            disabled={totalShipments > 0}
            onClick={() => {
              deleteCustomer();
            }}
          >
            <Trash2
              size="18"
              color={
                totalShipments > 0
                  ? theme.palette.brandColorThree.main
                  : theme.palette.brandColorTwo.main
              }
            />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default withTranslation()(ShowCustomers);
