import React, { useState, useEffect } from "react";
import { api } from "../axios/api";
import { useSelector } from "react-redux";
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Link,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation } from "react-i18next";
const ExportFile = (props) => {
  const user = useSelector((state) => state.user);
  const [exportType, setExportType] = useState("suppliers");
  const [isExported, setIsExported] = useState(false);
  const [exportedFile, setExportedFile] = useState({});
  const [historyDocuments, setHistoryDocuments] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    api
      .get("/getFiles/all_exported/" + user?.company_id)
      .then((res) => {
        console.log("history documents", res?.data);
        setHistoryDocuments(res?.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [user, isClicked]);

  const handleChange = (event) => {
    setExportType(event.target.value);
    console.log(event.target.value);
  };
  const exportFile = () => {
    setIsClicked(true);
    let url;
    if (exportType == "suppliers") {
      url = "/exportSupplier/" + user?.company_id;
    } else if (exportType == "customers") {
      url = "/exportCustomer/" + user?.company_id;
    } else if (exportType == "incoming-goods") {
      url = "/exportIncGoods/" + user?.company_id;
    } else if (exportType == "outgoing-goods") {
      url = "/exportOutGoods/" + user?.company_id;
    }

    api
      .post(url)
      .then((res) => {
        console.log("res", res?.data);
        setExportedFile(res?.data);
        setIsExported(true);
      })
      .catch((err) => {
        console.log("err", err);
        setIsClicked(false);
        toast.error("unable to export file!");
      });
  };
  return (
    <Box sx={{ padding: "20px 30px" }}>
      <ToastContainer />
      {/* <Box onClick={() => exportFile()}>Click to export Suppliers File</Box> */}
      <Typography
        variant="h6"
        sx={{
          color: theme.palette.heading.main,
          fontSize: "1.3rem",
          fontWeight: "600",
        }}
      >
        {props.t("Export CSV Files")}
      </Typography>
      <Box
        sx={{
          padding: "30px",
          backgroundColor: theme.palette.background.main,
          borderRadius: "4px",
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body2" sx={{ marginBottom: "20px" }}>
          {props?.t("Export selected data to CSV file and download it")}.
        </Typography>
        {isExported ? (
          <>
            <Typography
              variant="p"
              sx={{ color: theme.palette.brandColorOne.main }}
            >
              {exportType} {props?.t("succesfully csv created!")}
            </Typography>
            <Link
              href={exportedFile?.file_url}
              sx={{ width: "max-content" }}
              target="_blank"
              rel="noopener"
            >
              <Button
                sx={{
                  width: "max-content",
                  marginTop: "20px",
                  background: theme.palette.brandColorOne.main,
                  color: theme.palette.whiteFont.main,
                }}
                variant="contained"
              >
                {props?.t("Download File")}
              </Button>
            </Link>
            <Typography
              variant="body2"
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
                marginTop: "10px",
              }}
              onClick={() => {
                setIsExported(false);
                setIsClicked(false);
              }}
            >
              {props?.t("Export again")}
            </Typography>
          </>
        ) : (
          <>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                {props?.t("Select an option")}:
              </FormLabel>
              <RadioGroup
                aria-label="options"
                name="options"
                value={exportType}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="suppliers"
                  control={<Radio />}
                  label={props?.t("Suppliers")}
                />
                <FormControlLabel
                  value="customers"
                  control={<Radio />}
                  label={props?.t("Customers")}
                />
                <FormControlLabel
                  value="incoming-goods"
                  control={<Radio />}
                  label={props?.t("Incoming Goods")}
                />
                <FormControlLabel
                  value="outgoing-goods"
                  control={<Radio />}
                  label={props?.t("Outgoing Goods")}
                />
              </RadioGroup>
            </FormControl>
            <Button
              sx={{
                width: "max-content",
                marginTop: "20px",
                color: theme.palette.whiteFont.main,
                background: theme.palette.brandColorOne.main,
              }}
              disabled={isClicked}
              variant="contained"
              onClick={() => exportFile()}
            >
              {props?.t("Export")}
            </Button>
          </>
        )}
      </Box>
      <Box
        sx={{
          padding: "30px",
          backgroundColor: theme.palette.background.main,
          borderRadius: "4px",
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body2" sx={{ marginBottom: "20px" }}>
          {props?.t("Documents Export History")}
        </Typography>
        {historyDocuments?.length > 0 ? (
          <>
            <Box>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{props?.t("ID")}</TableCell>
                      <TableCell>{props?.t("File Type")}</TableCell>
                      <TableCell>{props?.t("Date Of Upload")}</TableCell>
                      <TableCell>{props?.t("Action")}</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {historyDocuments?.map((doc) => (
                      <TableRow key={doc?.id}>
                        <TableCell>{doc?.id}</TableCell>
                        <TableCell>{doc?.file_type}</TableCell>
                        <TableCell>
                          {new Date(doc?.uploading_date).toDateString()}
                        </TableCell>
                        <TableCell>
                          <Link
                            href={doc?.file_url}
                            sx={{ width: "max-content" }}
                            target="_blank"
                            rel="noopener"
                          >
                            <Button
                              size="small"
                              sx={{
                                width: "max-content",
                                marginTop: "20px",
                                color: theme.palette.whiteFont.main,
                                background: theme.palette.brandColorOne.main,
                              }}
                              variant="contained"
                            >
                              {props?.t("Download File")}
                            </Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        ) : (
          <>{props?.t("no history")}.</>
        )}
      </Box>
    </Box>
  );
};

export default withTranslation()(ExportFile);
