import React, { useState, useEffect } from "react";
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  IconButton,
  Button,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import ArrowUpwardIcon from "@mui/icons-material/ExpandLess";
import ArrowDownwardIcon from "@mui/icons-material/ExpandMore";
import { api } from "../axios/api";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import AddIncomingGoodsForm from "./AddIncomingGoodsForm";
import { Edit, Trash2 } from "react-feather";

const ShowIncomingGoods = (props) => {
  const theme = useTheme();
  const [goods, setGoods] = useState([]);
  const [editOptions, setEditOptions] = useState(null);
  const [filteredGoods, setFilteredGoods] = useState([]);

  const user = useSelector((state) => state.user);
  const [showAddForm, setShowAddForm] = useState(false);

  useEffect(() => {
    let body = {
      company_id: user?.company_id,
    };
    if (user?.company_id) {
      api
        .post("/getIncomingGoods", body)
        .then((res) => {
          setGoods(res?.data);
          setFilteredGoods(res?.data);
          setSearchingField("product_description");
          setSearchingTerm("");
        })
        .catch((err) => console.log("err", err));
    }
    if (!showAddForm) {
      setEditOptions(null);
    }
  }, [user, showAddForm]);

  const [sorting, setSorting] = useState({
    field: "id", // default sorting field
    order: "asc", // default sorting order
  });

  const handleSort = (field) => {
    const isAsc = sorting.field === field && sorting.order === "asc";
    setSorting({ field, order: isAsc ? "desc" : "asc" });
  };

  const sortedData = filteredGoods.sort((a, b) => {
    const order = sorting.order === "asc" ? 1 : -1;
    if (a[sorting.field] < b[sorting.field]) {
      return -1 * order;
    }
    if (a[sorting.field] > b[sorting.field]) {
      return 1 * order;
    }
    return 0;
  });

  /*******searching */
  const [searchingField, setSearchingField] = useState("product_description");
  const [searchingTerm, setSearchingTerm] = useState("");

  const handleSearch = (searchedterm) => {
    setSearchingTerm(searchedterm);
  };
  useEffect(() => {
    if (searchingTerm?.length > 0) {
      let filtered = goods?.filter((c) =>
        c[searchingField]?.toLowerCase()?.includes(searchingTerm?.toLowerCase())
      );
      setFilteredGoods(filtered);
    } else {
      setFilteredGoods(goods);
    }
  }, [searchingTerm, searchingField, goods]);

  return (
    <Box sx={{ padding: "20px 30px" }}>
      <ToastContainer />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.heading.main,
            fontSize: "1.3rem",
            fontWeight: "600",
          }}
        >
          {props.t("Incoming Goods")}
        </Typography>

        <Button
          variant="contained"
          onClick={() => setShowAddForm(!showAddForm)}
          sx={{
            backgroundColor: theme.palette.brandColorOne.main,
            color: "#fff",
          }}
          size="small"
        >
          {showAddForm
            ? props.t("View Incoming Goods")
            : props.t("Add Incoming Goods")}
        </Button>
      </Box>

      {showAddForm ? (
        <AddIncomingGoodsForm
          editOptions={editOptions}
          setShowFormModal={setShowAddForm}
        />
      ) : (
          <Box
              sx={{
                  marginTop: "20px",
                  backgroundColor: theme.palette.tableBg.main,
              }}
          >
              <Box
                  sx={{
                      my: 2,
                      p: 3,
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      justifyContent: "flex-end",
                  }}
              >
                  <Box
                      sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "transparent",
                          borderRadius: "9999px",
                          overflow: "hidden",
                          boxShadow: `inset 0px 0px 0px 1px ${theme.palette.secondary.main}`,
                          "&:hover": {
                              boxShadow: `inset 0px 0px 0px 2px ${theme.palette.secondary.main}`,
                          },
                          padding: "0 15px",
                      }}
                  >
                      <SearchIcon
                          sx={{
                              color: theme.palette.para.main,
                              p: "10",
                              flexGrow: 0,
                          }}
                      />
                      <InputBase
                          placeholder={props?.t("Search")}
                          value={searchingTerm}
                          onChange={(e) => handleSearch(e.target.value)}
                          sx={{
                              flexGrow: 1,
                              "& input": {
                                  padding: "10px",
                                  color: theme.palette.heading.main,
                              },
                          }}
                          inputProps={{"aria-label": "search"}}
                      />
                  </Box>
                  <Typography>{props?.t("by")}</Typography>
                  <Box>
                      <FormControl fullWidth>
                          <Select
                              sx={{height: "40px"}}
                              value={searchingField}
                              onChange={(e) => {
                                  setSearchingField(e.target.value);
                              }}
                          >
                              <MenuItem value="product_description">
                                  {props?.t("Product Description")}
                              </MenuItem>
                              <MenuItem value="product_category">
                                  {props?.t("Product Category")}
                              </MenuItem>
                              <MenuItem value="article_no">
                                  {props?.t("Article No.")}
                              </MenuItem>
                              <MenuItem value="batch_no">
                                  {props?.t("Batch No.")}
                              </MenuItem>
                              <MenuItem value="received_amount">
                                  {props?.t("Received Amount")}
                              </MenuItem>
                              <MenuItem value="unit">{props?.t("Unit")}</MenuItem>
                              <MenuItem value="trade_name">
                                  {props?.t("Trade Name")}
                              </MenuItem>
                              <MenuItem value="note">{props?.t("Note")}</MenuItem>
                          </Select>
                      </FormControl>
                  </Box>
              </Box>

              {searchingTerm?.length > 0 && (
                  <Box
                      sx={{
                          px: 2,
                          mx: 2,
                          mb: 2,
                          width: "max-content",
                          borderRadius: "2px",
                      }}
                  >
                      <Typography
                          variant="p"
                          sx={{color: theme.palette.primary.main}}
                      >
                          {props?.t("Filtered rows")}: {filteredGoods?.length} /{" "}
                          {goods?.length}
                      </Typography>{" "}
                  </Box>
              )}
              <Box container spacing={2} justifyContent="center">
                  <Box item xs={12}>
                      <TableContainer component={Paper}>
                          <Table sx={{backgroundColor: theme.palette.tableBg.main}}>
                              <TableHead>
                                  <TableRow>
                                      <TableCell sx={{whiteSpace: "nowrap"}}>
                                          <Box
                                              sx={{
                                                  display: "flex",
                                                  gap: "2px",
                                                  alignItems: "center",
                                              }}
                                          >
                                              <Box
                                                  sx={{
                                                      display: "flex",
                                                      gap: "-2px",
                                                      flexDirection: "column",
                                                      justifyContent: "center",
                                                  }}
                                              >
                                                  {" "}
                                                  <IconButton
                                                      size="small"
                                                      onClick={() =>
                                                          setSorting({field: "id", order: "asc"})
                                                      }
                                                  >
                                                      <ArrowUpwardIcon
                                                          size="small"
                                                          sx={{fontSize: "1rem"}}
                                                      />
                                                  </IconButton>
                                                  <IconButton
                                                      size="small"
                                                      onClick={() =>
                                                          setSorting({field: "id", order: "desc"})
                                                      }
                                                  >
                                                      <ArrowDownwardIcon
                                                          size="small"
                                                          sx={{fontSize: "1rem"}}
                                                      />
                                                  </IconButton>
                                              </Box>

                                              {props?.t("ID")}
                                          </Box>
                                      </TableCell>
                                      <TableCell sx={{whiteSpace: "nowrap"}}>
                                          {props?.t("Product Description")}
                                      </TableCell>
                                      <TableCell sx={{whiteSpace: "nowrap"}}>
                                          {props?.t("Product Category")}
                                      </TableCell>
                                      <TableCell sx={{whiteSpace: "nowrap"}}>
                                          {props?.t("Article No.")}
                                      </TableCell>
                                      <TableCell sx={{whiteSpace: "nowrap"}}>
                                          {props?.t("Batch No.")}
                                      </TableCell>

                                      <TableCell sx={{whiteSpace: "nowrap"}}>
                                          {props?.t("Received Amount")}
                                      </TableCell>
                                      <TableCell sx={{whiteSpace: "nowrap"}}>
                                          {props?.t("Unit")}
                                      </TableCell>
                                      <TableCell sx={{whiteSpace: "nowrap"}}>
                                          {" "}
                                          <Box
                                              sx={{
                                                  display: "flex",
                                                  gap: "2px",
                                                  alignItems: "center",
                                              }}
                                          >
                                              <Box
                                                  sx={{
                                                      display: "flex",
                                                      gap: "-2px",
                                                      flexDirection: "column",
                                                      justifyContent: "center",
                                                  }}
                                              >
                                                  {" "}
                                                  <IconButton
                                                      size="small"
                                                      onClick={() =>
                                                          setSorting({field: "sent_on", order: "asc"})
                                                      }
                                                  >
                                                      <ArrowUpwardIcon
                                                          size="small"
                                                          sx={{fontSize: "1rem"}}
                                                      />
                                                  </IconButton>
                                                  <IconButton
                                                      size="small"
                                                      onClick={() =>
                                                          setSorting({
                                                              field: "sent_on",
                                                              order: "desc",
                                                          })
                                                      }
                                                  >
                                                      <ArrowDownwardIcon
                                                          size="small"
                                                          sx={{fontSize: "1rem"}}
                                                      />
                                                  </IconButton>
                                              </Box>
                                              {props?.t("Sent On")}
                                          </Box>
                                      </TableCell>
                                      <TableCell sx={{whiteSpace: "nowrap"}}>
                                          {" "}
                                          <Box
                                              sx={{
                                                  display: "flex",
                                                  gap: "2px",
                                                  alignItems: "center",
                                              }}
                                          >
                                              <Box
                                                  sx={{
                                                      display: "flex",
                                                      gap: "-2px",
                                                      flexDirection: "column",
                                                      justifyContent: "center",
                                                  }}
                                              >
                                                  {" "}
                                                  <IconButton
                                                      size="small"
                                                      onClick={() =>
                                                          setSorting({
                                                              field: "received_on",
                                                              order: "asc",
                                                          })
                                                      }
                                                  >
                                                      <ArrowUpwardIcon
                                                          size="small"
                                                          sx={{fontSize: "1rem"}}
                                                      />
                                                  </IconButton>
                                                  <IconButton
                                                      size="small"
                                                      onClick={() =>
                                                          setSorting({
                                                              field: "received_on",
                                                              order: "desc",
                                                          })
                                                      }
                                                  >
                                                      <ArrowDownwardIcon
                                                          size="small"
                                                          sx={{fontSize: "1rem"}}
                                                      />
                                                  </IconButton>
                                              </Box>
                                              {props?.t("Received On")}
                                          </Box>
                                      </TableCell>
                                      <TableCell sx={{whiteSpace: "nowrap"}}>
                                          {" "}
                                          <Box
                                              sx={{
                                                  display: "flex",
                                                  gap: "2px",
                                                  alignItems: "center",
                                              }}
                                          >
                                              <Box
                                                  sx={{
                                                      display: "flex",
                                                      gap: "-2px",
                                                      flexDirection: "column",
                                                      justifyContent: "center",
                                                  }}
                                              >
                                                  {" "}
                                                  <IconButton
                                                      size="small"
                                                      onClick={() =>
                                                          setSorting({
                                                              field: "expiration_date",
                                                              order: "asc",
                                                          })
                                                      }
                                                  >
                                                      <ArrowUpwardIcon
                                                          size="small"
                                                          sx={{fontSize: "1rem"}}
                                                      />
                                                  </IconButton>
                                                  <IconButton
                                                      size="small"
                                                      onClick={() =>
                                                          setSorting({
                                                              field: "expiration_date",
                                                              order: "desc",
                                                          })
                                                      }
                                                  >
                                                      <ArrowDownwardIcon
                                                          size="small"
                                                          sx={{fontSize: "1rem"}}
                                                      />
                                                  </IconButton>
                                              </Box>
                                              {props?.t("Expiration Date")}
                                          </Box>
                                      </TableCell>
                                      <TableCell sx={{whiteSpace: "nowrap"}}>
                                          {props?.t("Trade")}{" "}
                                      </TableCell>
                                      <TableCell sx={{whiteSpace: "nowrap"}}>
                                          {props?.t("Note")}
                                      </TableCell>
                                      <TableCell sx={{whiteSpace: "nowrap"}}>
                                          {props?.t("Actions")}
                                      </TableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                  {sortedData?.map((good, i) => (
                                      <Good
                                          key={good.id}
                                          good={good}
                                          i={i}
                                          goods={goods}
                                          setGoods={setGoods}
                                          setShowAddForm={setShowAddForm}
                                          setEditOptions={setEditOptions}
                                          setFilteredGoods={setFilteredGoods}
                                      />
                                  ))}
                              </TableBody>
                          </Table>
                      </TableContainer>
                  </Box>
              </Box>
          </Box>
      )}
    </Box>
  );
};

export default withTranslation()(ShowIncomingGoods);

const Good = ({
  good,
  i,
  goods,
  setGoods,
  setEditOptions,
  setShowAddForm,
  setFilteredGoods,
}) => {
  const theme = useTheme();

  const deleteGood = () => {
    api
      .delete("/deleteIncomingGood/" + good?.id)
      .then((res) => {
        toast.success("Deleted Successfully!");
        setFilteredGoods(goods?.filter((c) => c?.id !== good?.id));
      })
      .catch((err) => {
        console.log("");
        toast.error("unable to delete!");
      });
  };
  const editGood = () => {
    setEditOptions(good);
    setShowAddForm(true);
  };
  return (
    <TableRow key={good?.id}>
      <TableCell sx={{ whiteSpace: "nowrap" }}>{good?.id}</TableCell>
      <TableCell sx={{ whiteSpace: "nowrap" }}>
        {good?.product_description}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap" }}>
        {good?.product_category}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap" }}>{good?.article_no}</TableCell>
      <TableCell sx={{ whiteSpace: "nowrap" }}>{good?.batch_no}</TableCell>

      <TableCell sx={{ whiteSpace: "nowrap" }}>
        {good?.received_amount}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap" }}>{good?.unit}</TableCell>
      <TableCell sx={{ whiteSpace: "nowrap" }}>
        {new Date(good?.sent_on)?.toDateString()}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap" }}>
        {new Date(good?.received_on)?.toDateString()}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap" }}>
        {new Date(good?.expiration_date)?.toDateString()}
      </TableCell>
      <TableCell sx={{ whiteSpace: "nowrap" }}>{good?.trade_name}</TableCell>
      <TableCell sx={{ whiteSpace: "nowrap" }}>{good?.delivery_note}</TableCell>
      <TableCell sx={{ whiteSpace: "nowrap" }}>
        <Box sx={{ display: "flex", gap: "5px" }}>
          <IconButton onClick={() => editGood()}>
            <Edit size="18" color={theme.palette.brandColorOne.main} />
          </IconButton>
          <IconButton
            onClick={() => {
              deleteGood();
            }}
          >
            <Trash2 size="18" color={theme.palette.brandColorTwo.main} />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};
