import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { setAuthToken } from "../axios/api";

export const PrivateRoute = ({ children }) => {
  // const user = useSelector((state) => state.user);
  let isAuthenticated = false;

  if (JSON.parse(localStorage.getItem("lucuma_token"))?.length > 10) {
    isAuthenticated = true;
    setAuthToken(JSON.parse(localStorage.getItem("lucuma_token")));
  }

  if (isAuthenticated) {
    return children;
  }

  return <Navigate to="/" />;
};
