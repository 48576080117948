import React, {useState, useEffect, useCallback} from "react";
import { api } from "../axios/api";
import { useSelector } from "react-redux";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  IconButton,
  Link,
} from "@mui/material";

import Modal from "@mui/material/Modal";

import { useTheme } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import AddIncomingGoodsForm from "./AddIncomingGoodsForm";
import AddOutgoingGoodsForm from "./AddOutgoingGoodsForm";
import AddSupplierFormImport from "./AddSupplierFormImport";
import LoadingSpinner from "./Loading/LoadingSpinner";
import DeleteIcon from '@mui/icons-material/Delete';

const ImportDeliveryNotes = (props) => {
  const user = useSelector((state) => state.user);
  const [historyDocuments, setHistoryDocuments] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [filesUploaded, setFilesUploaded] = useState(false);
  const [emailFilesUploaded, setEmailFilesUploaded] = useState(false);
  const [itemListIdx, setItemListIdx] = useState(0);
  const [extractedKeyValues, setExtractedKeyValues] = useState([]);
  const [loadingId, setLoadingId] = useState(-1);
  const [selectedOptionForProcess, setSelectedOptionForProcess] = useState(0);
  const [editOptionsForItem, setEditOptionsForItem] = useState({
    article_no: "",
    batch_no: "",
    product_description: "",
    product_category: "",
    received_amount: "",
    unit: "",
    received_on: "",
    expiration_date: "",
    sent_on: "",
    supplier_id: "",
    delivery_note_no: "",
  });
  const [showFormModal, setShowFormModal] = useState(false);
  const handleOpen = () => setShowFormModal(true);
  const handleClose = () => setShowFormModal(false);
  const [supplierEditOptions, setSupplierEditOptions] = useState({
    tradeName: "",
    streetAndHouseNo: "",
    zipCode: "",
    city: "",
    country: "",
    type: "supplier",
  });
  const [showSupplierFormModal, setShowSupplierFormModal] = useState(false);
  const handleOpenSupplier = () => setShowSupplierFormModal(true);
  const handleCloseSupplier = () => {
    setShowSupplierFormModal(false);
    return setShowFormModal(extractedKeyValues.item_list.length > 0);
  };
  const addItemNextStep = () => {
    const totalLen = extractedKeyValues.item_list.length;
      if (totalLen === 0)
          toast.warn(props?.t("No items detected on the document. Please add them manually in 'Incoming Goods' or 'Outgoing Goods' tab"));

      if (itemListIdx >= totalLen) {
      console.log("Items reached end of the list");
      setSelectedOptionForProcess(0);
      return;
    }
    setTimeout(() => {
      const currentItem = extractedKeyValues.item_list[itemListIdx];
      setEditOptionsForItem({
        article_no: "" + (itemListIdx + 1) + "/" + totalLen,
        batch_no: "",
        product_description: currentItem?.product_description,
        product_category: currentItem?.product_category,
        received_amount: currentItem?.amount,
        unit: currentItem?.unit,
        received_on: extractedKeyValues.delivery_note_date,
        expiration_date: "",
        sent_on: extractedKeyValues.delivery_note_date,
        supplier_id: "",
        delivery_note_no: extractedKeyValues.delivery_note_no,
      });
      setItemListIdx(itemListIdx + 1);
      setShowFormModal(true);
    });
  };
  const handleCloseSupplierWrapper = () => {
    setShowSupplierFormModal(false);
    addItemNextStep();
  };
  const theme = useTheme();

    const processImportedDoc = useCallback((doc) => {
        console.log("doc to be processed", doc);

        if (loadingId > -1){
            return;
        }
        setLoadingId(doc?.id);
        const postEndpoint = user.selectedBackend === "gpt" ? "/extractKeysAndValuesGPT" : "/extractKeysAndValues"
        api
            .post(postEndpoint, doc)
            .then((res) => {
                console.log("response.data", res?.data);
                if (res?.data !== null) {
                    console.log("response.data.supplier_id", res?.data.supplier_id);
                    setExtractedKeyValues(res?.data);
                    if (!res.data.supplier_id) {
                        console.log("will add supplier first ", res.data.supplier);
                        setSupplierEditOptions(res.data.supplier);
                        handleOpenSupplier();
                        setLoadingId(-1);
                    } else {
                        console.log("will edit items ");
                        handleOpen();
                        setLoadingId(-1);
                    }
                }
            })
            .catch((err) => toast.error("unable to process at the moment", err));
    }, [loadingId, user.selectedBackend]);

  useEffect(() => {
    api
      .get("/getFiles/incoming_goods_delivery_note/" + user?.company_id)
      .then((res) => {
        console.log("user ", user);
        console.log("history documents", res?.data);
        const sortedData = res?.data.sort((a, b) => b.id - a.id);
        setHistoryDocuments(sortedData);
        // sortedData.forEach(v => {
        //     if (v.isProcessed === false){
        //         console.log("will process doc " + v.id);
        //         processImportedDoc(v);
        //     } else {
        //         console.log("doc already processed " + v.id);
        //     }
        //
        // })
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [user, filesUploaded, processImportedDoc, emailFilesUploaded]);

  const [files, setFiles] = useState([]);

  const handleFileSelect = (event) => {
    setFiles([...files, ...event.target.files]);
  };

  const handleUpload = async () => {
    if (files?.length < 1) {
      return toast.error("no file selected");
    }
    setIsUploading(true);
    const formData = new FormData();
    formData.append("someKey", "someValue"); // Append additional data to the formData object
    console.log("files", files);

    for (let i = 0; i < files.length; i++) {
      console.log("file in loop", i, files[i]);
      formData.append("files", files[i]);
    }
    console.log("formData", formData);
    try {
      const response = await api.post(
        "/uploadIncomingDeliveryNotes/" + user?.company_id,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response);
      toast.success("successfully uploaded files!");
      setFiles([]);
      setIsUploading(false);
      setFilesUploaded(true);
    } catch (error) {
      console.error(error);
      toast.error("unable to upload files.");
      setIsUploading(false);
      setFilesUploaded(false);
    }
  };

    const handleMailtoClick = () => {
        window.location.href = 'mailto:importiere-in-lucuma+'+user.uniqueEmailToken+'@lucuma.io';
    };

    const handleDelete = (index) => {
        setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

  useEffect(() => {
    const fetchData = () => {
      const url =  "/importNotes/getMails/" + user?.uniqueEmailToken;
      console.log("fetching emails data " + url);
      api
        .get(url)
        .then((res) => {
          console.log("fetching emails data result ", res?.data);
          if (res?.data instanceof Array){
              res?.data.forEach((title) => {
                  toast.success("Imported new file from mail - " + title);
                  setEmailFilesUploaded(true);
              })
          } else {
              setEmailFilesUploaded(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setEmailFilesUploaded(false);
        });
    };

    // Fetch data immediately after component loads
    fetchData();
  }, [user]);


  const renderSelected = () => {
      if (selectedOptionForProcess === 0){
          return <Box
              sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  py: 2,
              }}
          >
              <Typography>Import Delivery Note as </Typography>
              <Button
                  onClick={() => setSelectedOptionForProcess(1)}
                  variant="contained"
                  size="small"
                  sx={{ width: "max-content", color: "#fff" }}
              >
                  Incoming Goods
              </Button>
              <Typography>OR</Typography>
              <Button
                  onClick={() => setSelectedOptionForProcess(2)}
                  variant="contained"
                  size="small"
                  sx={{ width: "max-content", color: "#fff" }}
              >
                  Outgoing Goods
              </Button>
          </Box>;
      } else if (selectedOptionForProcess === 1) {
          return <AddIncomingGoodsForm
              setShowFormModal={setShowFormModal}
              nextStep={addItemNextStep}
              editOptions={editOptionsForItem}
          />;
      } else {
          return <AddOutgoingGoodsForm
              setShowFormModal={setShowFormModal}
              nextStep={addItemNextStep}
              editOptions={editOptionsForItem}
          />;
      }
    }

  return (
    <Box sx={{ padding: "20px 30px" }}>
      <ToastContainer />
      <Modal
        open={showSupplierFormModal}
        onClose={handleCloseSupplier}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            maxWidth: "80vw",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            px: 4,
            py: 2,
            maxHeight: "90vh",
            overflowY: "scroll",
          }}
        >
          <AddSupplierFormImport
            extractedKeyValues={extractedKeyValues}
            editOptions={supplierEditOptions}
            setNextStep={handleCloseSupplierWrapper}
          />
        </Box>
      </Modal>
      <Modal
        open={showFormModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            maxWidth: "80vw",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            px: 4,
            py: 2,
            maxHeight: "90vh",
            overflowY: "scroll",
          }}
        >
          {renderSelected()}
        </Box>
      </Modal>
      {/* <Box onClick={() => exportFile()}>Click to export Suppliers File</Box> */}
      <Typography
        variant="h6"
        sx={{
          color: theme.palette.heading.main,
          fontSize: "1.3rem",
          fontWeight: "600",
        }}
      >
        {props.t("Import Delivery Notes")}
      </Typography>
      <Box
        sx={{
          padding: "30px",
          backgroundColor: theme.palette.background.main,
          borderRadius: "4px",
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h7" sx={{ marginBottom: "20px" }}>
          {props?.t(
            "In order to import a Delivery Note you can either select a local file in the box below or send it as an " +
              "attachment to the dedicated email address (inbox is scanned for new items after page refresh)."
          )}
        </Typography>

        {isUploading ? (
          <Box>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2">
                {props?.t("Files upload in progress")}
              </Typography>
            </Grid>
          </Box>
        ) : filesUploaded ? (
          <Box>
            <Grid item xs={12}>
              <Typography variant="h5" component="h2">
                {props?.t("Files uploaded successfully")}!
              </Typography>
              <Typography
                sx={{
                  textDecoration: "underline",
                  marginTop: "20px",
                  cursor: "pointer",
                }}
                onClick={() => setFilesUploaded(false)}
              >
                {props?.t("Import again")}
              </Typography>
            </Grid>
          </Box>
        ) : (
          <>
              <Grid container spacing={1} justifyContent="center" alignItems="center" >
                  <Grid item xs={12} sm={8} md={4} lg={2}>
                      <Box
                          border={1}
                          borderColor="grey.400"
                          borderRadius={2}
                          padding={2}
                          minHeight={120}
                          textAlign="center"
                      >
                          <Typography variant="h6" gutterBottom>
                              {props?.t("Upload a File")}
                          </Typography>
                          <input
                              accept="image/*, .pdf, .doc, .docx"
                              style={{ display: 'none' }}
                              id="contained-button-file"
                              type="file"
                              multiple
                              onChange={handleFileSelect}
                          />
                          <label htmlFor="contained-button-file">
                              <Button variant="contained" component="span"
                                            sx={{
                                              color: theme.palette.whiteFont.main,
                                              background: theme.palette.brandColorOne.main,
                                            }}
                              >
                                  {props?.t("Choose File(s)")}
                              </Button>
                          </label>
                      </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={6} lg={4}>
                      <Box
                          sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              padding: 2,
                              border: 1,
                              borderColor: 'grey.400',
                              borderRadius: 2,
                              minHeight: 120,
                          }}
                      >
                          <Typography variant="h6" gutterBottom>
                              {props?.t("Send to dedicated email")}
                          </Typography>
                          <Button
                              onClick={handleMailtoClick}
                              variant="contained"
                              component="span"
                              fullWidth
                              sx={{
                                  color: theme.palette.whiteFont.main,
                                  background: theme.palette.brandColorOne.main,
                              }}
                          >
                              importiere-in-lucuma+{user.uniqueEmailToken}@lucuma.io
                          </Button>
                      </Box>
                  </Grid>
                  {(files?.length > 0) ?
                      (<Grid item xs={12}>
                          <Box mt={4}>
                              <Typography variant="h6" gutterBottom>
                                  {props?.t("Selected Files")}
                              </Typography>
                              <Grid container spacing={2}>
                                  {files.map((file, index) => (
                                      <Grid item xs={12} key={index}>
                                          <Box
                                              display="flex"
                                              alignItems="center"
                                              justifyContent="space-between"
                                              border={1}
                                              borderColor="grey.300"
                                              borderRadius={2}
                                              padding={1}
                                              textAlign="center"
                                              component={Paper}
                                              elevation={1}
                                          >
                                              <Typography variant="body2" noWrap>
                                                  {file.name}
                                              </Typography>
                                              <IconButton
                                                  aria-label="delete"
                                                  onClick={() => handleDelete(index)}
                                              >
                                                  <DeleteIcon />
                                              </IconButton>
                                          </Box>
                                      </Grid>
                                  ))}
                              </Grid>
                          </Box>
                          <Box mt={4}>
                              <Button
                                variant="contained"
                                onClick={handleUpload}
                                sx={{
                                  color: theme.palette.whiteFont.main,
                                  background: theme.palette.brandColorOne.main,
                                }}
                              >
                                {props?.t("Upload")}
                              </Button>
                          </Box>
                    </Grid>)
                  : (<div></div>)}
              </Grid>
          </>
        )}
      </Box>

      <Box
        sx={{
          padding: "30px",
          backgroundColor: theme.palette.background.main,
          borderRadius: "4px",
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body2" sx={{ marginBottom: "20px" }}>
          {props?.t("Import History")}
        </Typography>
        {historyDocuments?.length > 0 ? (
            <Box>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{props?.t("ID")}</TableCell>
                                <TableCell sx={{maxWidth: "200px"}}>
                                    {props?.t("Name")}
                                </TableCell>
                                <TableCell>{props?.t("File")} </TableCell>
                                <TableCell>{props?.t("Uploaded By")}</TableCell>
                                <TableCell>{props?.t("Date Of Upload")}</TableCell>
                                <TableCell>{props?.t("Action")}</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {historyDocuments?.map((doc) => (
                                <TableRow key={doc?.id}>
                                    <TableCell>{doc?.id}</TableCell>
                                    <TableCell sx={{maxWidth: "200px"}}>
                                        {doc?.file_name}
                                    </TableCell>
                                    <TableCell>
                                        {/* {isPdf(doc?.file_url) ? (
                            // <Thumbnail url={doc?.file_url} />
                            <Document
                              file={doc?.file_url}
                              onLoadSuccess={onDocumentSuccess}
                            >
                              <Page pageNumber={pageNumber} width={150} />
                            </Document>
                          ) : isJpg(doc?.file_url) ? (
                            <img src={doc?.file_url} alt="thumbnail" />
                          ) : (
                            <div>No preview available</div>
                          )} */}
                                        <Link
                                            href={doc?.file_url}
                                            sx={{width: "max-content"}}
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            {" "}
                                            <Typography>{props?.t("View File")}</Typography>
                                        </Link>
                                    </TableCell>
                                    <TableCell>{doc?.uploaded_by}</TableCell>
                                    <TableCell>
                                        {new Date(doc?.uploading_date).toDateString()}
                                    </TableCell>
                                    {
                                        doc?.isProcessed ? (
                                            <TableCell><Button
                                                size="small"
                                                variant="outlined"
                                                disabled
                                            >
                                                {props?.t("Processed")}
                                            </Button></TableCell>
                                        ) : (
                                            <TableCell>
                                                {loadingId === doc?.id ? (
                                                    <LoadingSpinner/>
                                                ) : (
                                                    <Button
                                                        size="small"
                                                        sx={{
                                                            width: "max-content",
                                                            marginTop: "20px",
                                                            color: theme.palette.whiteFont.main,
                                                            background: theme.palette.brandColorOne.main,
                                                        }}
                                                        variant="contained"
                                                        onClick={() => processImportedDoc(doc)}
                                                    >
                                                        {props?.t("Process")}
                                                    </Button>
                                                )}
                                                {/* </Link> */}
                                            </TableCell>
                                        )
                                    }

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        ) : (
          <>{props?.t("no history")}.</>
        )}
      </Box>
    </Box>
  );
};

export default withTranslation()(ImportDeliveryNotes);
