import React, { useState, useEffect } from "react";

import {
  Container,
  Box,
  Typography,
  Avatar,
} from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { api } from "../axios/api";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";

import { useTheme } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import CircularProgress from "@mui/material/CircularProgress";

const VerifyAccount = () => {
  const [isAccountVerified, setIsAccountVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const theme = useTheme();
  const navigate = useNavigate();
  const { userId, uniqueString } = useParams();
  useEffect(() => {
    console.log("params", userId, uniqueString);
    api
      .post("/verifyAccount", { userId, uniqueString })
      .then((res) => {
        console.log("response from accout verification", res?.data);
        setIsAccountVerified(true);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        // toast.error('')
        setIsAccountVerified(false);
        setIsLoading(false);
      });
  }, [userId, uniqueString]);

  return (
    <Box>
      <ToastContainer />
      <Box
        sx={{
          //   backgroundColor: theme.palette.brandColorOne.main,
          backgroundColor: theme.palette.secondary.main,
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Container maxWidth="sm">
          {isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <Box
              sx={{
                backdropFilter: "blur(5px)",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                p: 4,
                borderRadius: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ bgcolor: theme.palette.primary.main, mb: 2 }}>
                <PersonIcon />
              </Avatar>
              <Typography component="h1" variant="h5" color="textPrimary">
                Account Verification
              </Typography>
              {isAccountVerified ? (
                  <Box
                      sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                      }}
                  >
                      <DoneOutlineIcon
                          sx={{
                              color: "green",
                              marginTop: "20px",
                              marginBottom: "10px",
                              border: "1px solid lightgray",
                              width: "50px",
                              height: "50px",
                              padding: "10px",
                              borderRadius: "50%",
                          }}
                      />
                      <Typography>Account Verified!</Typography>
                      <Typography
                          variant="body2"
                          sx={{
                              marginTop: "20px",
                              textDecoration: "underline",
                              cursor: "pointer",
                          }}
                          onClick={() => navigate("/")}
                      >
                          Continue to the login
                      </Typography>
                  </Box>
              ) : (
                  <Box
                      sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                      }}
                  >
                      <CancelTwoToneIcon
                          sx={{
                              color: "red",
                              marginTop: "20px",
                              marginBottom: "10px",
                              //   border: "1px solid lightgray",
                              width: "100px",
                              height: "100px",
                              padding: "10px",
                              borderRadius: "50%",
                          }}
                      />
                      <Typography>Unable to verify your account!</Typography>
                  </Box>
              )}
            </Box>
          )}{" "}
        </Container>
      </Box>
    </Box>
  );
};

export default VerifyAccount;
