import DashboardLayout from "../Layout/DashboardLayout";
import DashboardContent from "../container/DashboardContent";

const App = () => {
  return (
    <DashboardLayout>
      {(selectedItem) => <DashboardContent selectedItem={selectedItem} />}
    </DashboardLayout>
  );
};

export default App;
