import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
} from "@mui/material";
import { api } from "../axios/api";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import AddUserForm from "./AddUserForm";
import { ToastContainer, toast } from "react-toastify";
import { withTranslation } from "react-i18next";

const AddUsers = (props) => {
  const theme = useTheme();
  const [users, setUsers] = useState([]);
  const user = useSelector((state) => state.user);
  const [showAddForm, setShowAddForm] = useState(false);
  const [editOptions, setEditOptions] = useState({});

  useEffect(() => {
    console.log("user at show contact", user);
  }, [user]);
  useEffect(() => {
    api
      .get("/getUsers")
      .then((res) => {
        console.log("response from getting users", res?.data);
        setUsers(res?.data);
      })
      .catch((err) => console.log("err", err));
    if (!showAddForm) {
      setEditOptions({});
    }
  }, [user, showAddForm]);

  return (
    <Box sx={{ padding: "20px 30px" }}>
      <ToastContainer />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.heading.main,
            fontSize: "1.3rem",
            fontWeight: "600",
          }}
        >
          {props?.t("Users")}
        </Typography>
        <Button
          variant="contained"
          onClick={() => setShowAddForm(!showAddForm)}
          sx={{
            backgroundColor: theme.palette.primary.main,
            color: "#fff",
          }}
          size="small"
        >
          {showAddForm ? props?.t("View Users") : props?.t("Add Users")}
        </Button>
      </Box>

      {showAddForm ? (
        <AddUserForm
          editOptions={editOptions}
          setShowAddForm={setShowAddForm}
        />
      ) : (
          <Box sx={{marginTop: "20px"}}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                            sx={{
                              width: "50px",
                              color: theme.palette.primary.main,
                            }}
                        >
                          {props?.t("ID")}
                        </TableCell>
                        {/* <TableCell>Customer ID</TableCell> */}
                        <TableCell
                            sx={{
                              color: theme.palette.primary.main,
                            }}
                        >
                          {props?.t("First Name")}
                        </TableCell>
                        <TableCell
                            sx={{
                              color: theme.palette.primary.main,
                            }}
                        >
                          {props?.t("Last Name")}
                        </TableCell>
                        <TableCell
                            sx={{
                              color: theme.palette.primary.main,
                            }}
                        >
                          {props?.t("Email")}
                        </TableCell>

                        <TableCell
                            sx={{
                              color: theme.palette.primary.main,
                            }}
                        >
                          {props?.t("Actions")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users?.map((contact, i) => (
                          <NormalUser
                              contact={contact}
                              i={i}
                              users={users}
                              setUsers={setUsers}
                              setShowAddForm={setShowAddForm}
                              setEditOptions={setEditOptions}
                              trans={props}
                          />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
      )}
    </Box>
  );
};

const NormalUser = ({
  contact,
  i,
  users,
  setUsers,
  setEditOptions,
  setShowAddForm,
  trans,
}) => {
  const theme = useTheme();

  const deleteCustomer = () => {
    console.log("contact id", contact?.id);
    api
      .delete("/deleteUserByAdmin/" + contact?.id)
      .then((res) => {
        console.log("delete user response", res?.data);
        toast.success("Deleted Successfully!");
        setUsers(users?.filter((c) => c?.id !== contact?.id));
      })
      .catch((err) => {
        console.log("error in deleting user", err);
        toast.error("unable to delete!");
      });
  };

  return (
    <TableRow
      key={contact?.id}
      style={{
        backgroundColor:
          i % 2 === 0 ? theme.palette.secondary.main : "transparent",
      }}
    >
      <TableCell sx={{ width: "50px" }}>{contact?.id}</TableCell>
      <TableCell>{contact?.first_name}</TableCell>
      <TableCell>{contact?.last_name}</TableCell>
      <TableCell>{contact?.email_address}</TableCell>

      <TableCell>
        <Box sx={{ display: "flex", gap: "5px", flexDirection: "column" }}>
          <>
            {" "}
            <Button
              onClick={() => {
                deleteCustomer();
              }}
              variant="contained"
            >
              {trans?.t("Delete")}
            </Button>
          </>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default withTranslation()(AddUsers);
