import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Avatar,
  Divider,
} from "@mui/material";
import { api } from "../axios/api";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import { withTranslation } from "react-i18next";
import CompanyFormModal from "./CompanyFormModal";

const Profile = (props) => {
  const theme = useTheme();
  const user = useSelector((state) => state.user);
  const [companyData, setCompanyData] = useState({});
  const [showCompanyDataUpdateModal, setShowCompanyDataUpdateModal] =
    useState(false);

  useEffect(() => {
    user?.id &&
      api
        .get("/getCompanyDetails/" + user?.company_id)
        .then((res) => {
          console.log("response from getting company data", res.data);
          setCompanyData(res?.data);
        })
        .catch((err) => console.log(err));
  }, [user?.company_id, user?.id]);
  useEffect(() => {
    api
      .get("/getCompanyDetails/" + user?.company_id)
      .then((res) => {
        console.log("response from getting company data", res.data);
        setCompanyData(res?.data);
      })
      .catch((err) => console.log(err));
    // }
  }, [user]);
  return (
    <Box sx={{ padding: "20px 30px" }}>
      <CompanyFormModal
        showModal={showCompanyDataUpdateModal}
        isEditType={true}
        setShowCompanyDataUpdateModal={setShowCompanyDataUpdateModal}
        companyData={companyData}
      />

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h6"
          sx={{
            color: theme.palette.heading.main,
            fontSize: "1.3rem",
            fontWeight: "600",
          }}
        >
          {props.t("Profile Settings")}
        </Typography>
      </Box>
      <Box
        sx={{
          background: theme.palette.background.main,
          padding: "30px",
          minHeight: "200px",
          borderRadius: "4px",
          marginTop: "30px",
        }}
      >
        <ToastContainer />
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            marginBottom: "15px",
          }}
        >
          <Avatar sx={{ backgroundColor: theme.palette.brandColorTwo.main }}>
            {user?.first_name[0]?.toUpperCase()}
            {user?.last_name[0]?.toUpperCase()}
          </Avatar>
          <Typography>
            {" "}
            {user?.first_name?.toUpperCase()} {user?.last_name?.toUpperCase()}
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
          }}
        >
          <Typography variant="h6" color={theme.palette.brandColorOne.main}>
            {props?.t("Company Details")}
          </Typography>{" "}
          <Button
            variant="contained"
            onClick={() => setShowCompanyDataUpdateModal(true)}
            sx={{
              backgroundColor: theme.palette.brandColorOne.main,
              color: "#fff",
            }}
            size="small"
          >
            {props.t("Edit Company Details")}
          </Button>
        </Box>

        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box>
            <Typography
              variant="body2"
              sx={{
                fontSize: "0.8rem",
                color: theme.palette.heading.main,
                fontWeight: "600",
              }}
            >
              {props?.t("Company Name")}
            </Typography>
            <Typography variant="p" sx={{ color: theme.palette.para.main }}>
              {companyData?.company_name}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{
                fontSize: "0.8rem",
                color: theme.palette.heading.main,
                fontWeight: "600",
              }}
            >
              {props?.t("Address")}
            </Typography>
            <Typography variant="p" sx={{ color: theme.palette.para.main }}>
              {companyData?.address}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{
                fontSize: "0.8rem",
                color: theme.palette.heading.main,
                fontWeight: "600",
              }}
            >
              {props?.t("City")}
            </Typography>
            <Typography variant="p" sx={{ color: theme.palette.para.main }}>
              {companyData?.city}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="body2"
              sx={{
                fontSize: "0.8rem",
                color: theme.palette.heading.main,
                fontWeight: "600",
              }}
            >
              {props?.t("ZIP Code")}
            </Typography>
            <Typography variant="p" sx={{ color: theme.palette.para.main }}>
              {companyData?.zip_code}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default withTranslation()(Profile);
