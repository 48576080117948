import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { TextField, Button, Grid, Box } from "@mui/material";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { api } from "../axios/api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next";
const validationSchema = Yup.object().shape({
  tradeName: Yup.string().required("Required"),

  streetAndHouseNo: Yup.string().required("Required"),
  zipCode: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
});

const AddSupplierFormImport = (props) => {
  let { editOptions, setNextStep } = props;
  const theme = useTheme();
  const [initialValues, setInitialValues] = useState(
    editOptions
      ? {
          tradeName: editOptions?.trade_name,
          streetAndHouseNo: editOptions?.street_and_house_no,
          zipCode: editOptions?.zip_code,
          city: editOptions?.city,
          country: editOptions?.country,
          type: "supplier",
        }
      : {
          tradeName: "",
          streetAndHouseNo: "",
          zipCode: "",
          city: "",
          country: "",
          type: "supplier",
        }
  );

  useEffect(() => {
    console.log("edit options at add supplier form", editOptions);
  }, [editOptions]);

  const user = useSelector((state) => state.user);
  useEffect(() => {
    console.log("user at add contact", user);
  }, [user]);

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    if (editOptions?.id) {
      let body = {
        trade_name: values.tradeName,
        street_and_house_no: values.streetAndHouseNo,
        zip_code: values.zipCode,
        city: values.city,
        country: values.country,
        type: values.type,
        company_id: user?.company_id,
      };

      api
        .put("/updateSupplier/" + editOptions?.id, body)
        .then((res) => {
          console.log("response from updating content", res?.data);
          toast.success("contact updated successfully!");
          setNextStep(res?.data.id);

          resetForm();
        })
        .catch((err) => {
          console.log("err", err);
          toast.error("error in updating contact");
        });
    } else {
      let body = {
        trade_name: values.tradeName,
        street_and_house_no: values.streetAndHouseNo,
        zip_code: values.zipCode,
        city: values.city,
        country: values.country,
        type: values.type,
        company_id: user?.company_id,
      };

      api
        .post("/postSupplier", body)
        .then((res) => {
          console.log("response from posting content", res?.data);
          toast.success("contact added successfully!");
          resetForm();
          setTimeout(() => {
            setNextStep(res?.data.id);
          }, 2000);
        })
        .catch((err) => {
          console.log("err", err);
          toast.error("error in adding contact");
        });
    }
  };

  const cancelForm = () => {
    setNextStep(null);
  };

  return (
    <Box sx={{ padding: "30px 0px" }}>
      {/* <Typography
        variant="h6"
        sx={{
          color: theme.palette.heading.main,
          fontSize: "1.3rem",
          fontWeight: "600",
        }}
      >
        Add Contact
      </Typography> */}
      <Box
        sx={{
          backgroundColor: theme.palette.background.main,
          padding: "20px",
          borderRadius: "5px",
          marginTop: "20px",
          maxWidth: "700px",
        }}
      >
        <Typography
          variant="body2"
          sx={{ margin: "10px 0 30px 0", color: theme.palette.para.main }}
        >
          {props?.t(
            "Supplier as extracted from the invoice. You can save the info or Cancel in order to proceed to the next step."
          )}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    name="tradeName"
                    as={TextField}
                    label={props?.t("Trade Name")}
                    fullWidth
                    error={touched.tradeName && Boolean(errors.tradeName)}
                    helperText={touched.tradeName && errors.tradeName}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    name="streetAndHouseNo"
                    as={TextField}
                    label={props?.t("Street and House-No.")}
                    fullWidth
                    error={
                      touched.streetAndHouseNo &&
                      Boolean(errors.streetAndHouseNo)
                    }
                    helperText={
                      touched.streetAndHouseNo && errors.streetAndHouseNo
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    name="zipCode"
                    as={TextField}
                    label={props?.t("Zip Code")}
                    fullWidth
                    error={touched.zipCode && Boolean(errors.zipCode)}
                    helperText={touched.zipCode && errors.zipCode}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    name="city"
                    as={TextField}
                    label={props?.t("Town/City")}
                    fullWidth
                    error={touched.city && Boolean(errors.city)}
                    helperText={touched.city && errors.city}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Field
                    name="country"
                    as={TextField}
                    label={props?.t("Country")}
                    fullWidth
                    error={touched.country && Boolean(errors.country)}
                    helperText={touched.country && errors.country}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <Field name="type">
                    {({ field }) => (
                      <RadioGroup {...field} row>
                        <FormControlLabel
                          value="supplier"
                          control={<Radio defaultChecked={true} />}
                          label="Supplier"
                        />
                        <FormControlLabel
                          value="consignee"
                          control={<Radio />}
                          label="Consignee"
                        />
                      </RadioGroup>
                    )}
                  </Field>
                </Grid> */}
                <Grid item xs={12} gap={4}>
                  <Button
                    variant="contained"
                    // color={theme.palette.brandColorOne.main}
                    sx={{
                      backgroundColor: theme.palette.brandColorOne.main,
                      color: theme.palette.whiteFont.main,
                    }}
                    type="submit"
                    size="small"
                  >
                    {editOptions?.id ? props?.t("Update") : props?.t("Save")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => cancelForm()}
                    sx={{
                      marginLeft: "10px",
                      backgroundColor: theme.palette.brandColorOne.main,
                      color: theme.palette.whiteFont.main,
                    }}
                    size="small"
                  >
                    {props?.t("Cancel")}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default withTranslation()(AddSupplierFormImport);
