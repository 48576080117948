import * as React from "react";
import { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { Avatar, Link } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Drawer from "@mui/material/Drawer";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import Tooltip from "@mui/material/Tooltip";
import "react-toastify/dist/ReactToastify.css";

import {
  AlignLeft,
  Settings,
  LogOut,
  Truck,
  ShoppingCart,
  ArrowDownCircle,
  ArrowUpCircle,
  Upload,
  Download,
  User,
  HelpCircle,
} from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { setUser, setToken, setViewMode } from "../actions/userActions";
import { useNavigate } from "react-router-dom";
import { api } from "../axios/api";
import CompanyFormModal from "../components/CompanyFormModal";
import LanguageDropdown from "../components/Dropdowns/LanguageDropdown";
import { withTranslation } from "react-i18next";
import Switch from "@mui/material/Switch";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  fontSize: "15px !important",
  color: theme.palette.menuItem.main,
  "&:hover": {
    backgroundColor: theme.palette.selectedMenuBack.main,
  },
  "&:active": {
    color: theme.palette.selectedMenuFont.main,
    backgroundColor: theme.palette.selectedMenuBack.main,
  },
}));

const drawerWidth = 260;

function DashboardLayout(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isCompanyDataExist, setIsCompanyDataExist] = useState({});
  const user = useSelector((state) => state.user);
  const mode = useSelector((state) => state.viewMode);
  const refreshTrigger = useSelector((state) => state.refreshTrigger);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    api
      .get("/getUserData")
      .then((res) => {
        console.log("response from getUserData", res.data);
        dispatch(setUser(res.data));
        setIsCompanyDataExist(!!res?.data?.company_id);
      })
      .catch((err) => console.log("getUserData err", err));
  }, [dispatch, refreshTrigger]);

  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  useEffect(() => {
    if (user?.isAdmin) {
      setSelectedItem("addUsers");
    } else {
      setSelectedItem("suppliers");
    }
  }, [user]);
  const menuItems = user?.isAdmin
    ? [
        {
          label: props.t("Add Users"),
          value: "addUsers",
          icon: (
            <User
              size={17}
              //   color={theme.palette.menuHeading.main}
              strokeWidth={1.5}
            />
          ),
        },
      ]
    : [
        {
          label: props.t("Suppliers"),
          value: "suppliers",
          icon: (
            <Truck
              size={17}
              //   color={theme.palette.menuHeading.main}
              strokeWidth={1.5}
            />
          ),
        },
        {
          label: props.t("Customers"),
          value: "customers",
          icon: (
            <ShoppingCart
              size={17}
              //   color={theme.palette.menuHeading.main}
              strokeWidth={1.5}
            />
          ),
        },
        {
          label: props.t("Incoming Goods"),
          value: "incomingGoods",
          icon: (
            <ArrowDownCircle
              size={17}
              //   color={theme.palette.menuHeading.main}
              strokeWidth={1.5}
            />
          ),
        },
        {
          label: props.t("Outgoing Goods"),
          value: "outgoingGoods",
          icon: (
            <ArrowUpCircle
              size={17}
              //   color={theme.palette.menuHeading.main}
              strokeWidth={1.5}
            />
          ),
        },
        // {
        //   label: props.t("Import File"),
        //   value: "importFile",
        //   icon: (
        //     <Download
        //       size={17}
        //       //   color={theme.palette.menuHeading.main}
        //       strokeWidth={1.5}
        //     />
        //   ),
        // },

        {
          label: props.t("Export File"),
          value: "exportFile",
          icon: (
            <Upload
              size={17}
              //   color={theme.palette.menuHeading.main}
              strokeWidth={1.5}
            />
          ),
        },
        {
          label: props.t("Import Delivery Notes"),
          value: "importDeliveryNotes",
          icon: (
            <Download
              size={17}
              //   color={theme.palette.menuHeading.main}
              strokeWidth={1.5}
            />
          ),
        },
      ];

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleItemClick = (item) => {
    console.log("item", item);
    setSelectedItem(item?.value);
  };
  const logout = () => {
    dispatch(setUser({}));
    dispatch(setToken(""));
    navigate("/");
    localStorage.setItem("lucuma_user", null);
    localStorage.setItem("lucuma_token", null);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openProfileDropdown = Boolean(anchorEl);
  const handleProfileDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileDropdownClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const openDataDropdown = Boolean(anchorEl2);
  const handleDataDropdownClick = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleDataDropdownClose = () => {
    setAnchorEl2(null);
  };
  const handleModeChange = () => {
    dispatch(setViewMode(mode === "dark" ? "light" : "dark"));
  };

  const drawer = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",

        paddingBottom: "100px",
      }}
    >
      <Toolbar>
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          {" "}
          <Box
            sx={{
              margin: "30px 0",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img alt=""
              src="https://lucumafiles.s3.eu-central-1.amazonaws.com/lucumaLogo.png"
              style={{ width: "150px" }}
            />
          </Box>
        </Box>
      </Toolbar>

      <List sx={{ padding: "0 18px", marginTop: "20px" }}>
        {menuItems?.map((item, index) => (
          <StyledListItem
            key={item?.value}
            disablePadding
            sx={{ display: "block", marginBottom: "15px" }}
            selected={selectedItem === item?.value}
            onClick={() => handleItemClick(item)}
          >
            <ListItemButton
              sx={{
                minHeight: 30,
                justifyContent: "initial",
                px: 1.5,
                py: 1.3,
                backgroundColor:
                  selectedItem === item?.value
                    ? theme.palette.selectedMenuBack.main
                    : "transparent",
                color:
                  selectedItem === item?.value &&
                  theme.palette.selectedMenuFont.main,
                "&:hover": {
                  backgroundColor: theme.palette.selectedMenuBack.main,
                },

                borderRadius: "3px",
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 1.7,
                  justifyContent: "center",
                  color:
                    selectedItem === item?.value
                      ? theme.palette.selectedMenuFont.main
                      : theme.palette.menuHeading.main,
                }}
              >
                {item?.icon}
              </ListItemIcon>

              <Typography
                style={{
                  opacity: 1,
                  fontWeight: selectedItem === item?.value ? "500" : 400,
                  fontSize: selectedItem === item?.value ? "15px" : "14px",
                  color:
                    selectedItem === item?.value
                      ? theme.palette.selectedMenuFont.main
                      : theme.palette.menuItem.main,
                }}
              >
                {" "}
                {item?.label}
              </Typography>
            </ListItemButton>
          </StyledListItem>
        ))}
      </List>

      <Box
        sx={{
          position: "fixed",
          bottom: "0",
          width: `${drawerWidth}px`,
        }}
      ></Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <CssBaseline />
      <CompanyFormModal
        showModal={!!(user?.id && !user?.isAdmin && !isCompanyDataExist)}
      />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: theme.palette.background.main,
          boxShadow: " rgba(33, 35, 38, 0.1) 0px 10px 10px -10px",
          // border: "2px solid red",
        }}
      >
        <Toolbar
          sx={{
            backgroundColor: theme.palette.background.main,
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            {/* <MenuIcon /> */}
            <AlignLeft
              size={24}
              color={theme.palette.iconColor.main}
              strokeWidth={0.7}
            />
          </IconButton>

          <Box
            sx={{
              flex: "1",
              margin: "0 10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                marginLeft: "auto",
                width: "max-content",

                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "2px",
              }}
            >
              {/* <SearchComponent /> */}
              {/* <Bell
                size={22}
                color={theme.palette.iconColor.main}
                strokeWidth={2}
              /> */}
              <LanguageDropdown />
              {/* <IconButton onClick={() => setSelectedItem("account")}>
                <Settings
                  size={22}
                  color={theme.palette.iconColor.main}
                  strokeWidth={2}
                />
              </IconButton> */}
              <Tooltip title={props?.t("Data settings")}>
                <IconButton
                  onClick={handleDataDropdownClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "data-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <HelpCircle
                    size={24}
                    color={theme.palette.menuHeading.main}
                    strokeWidth={1.7}
                  />
                </IconButton>
              </Tooltip>

              <Tooltip title={props?.t("Account settings")}>
                <IconButton
                  onClick={handleProfileDropdownClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar
                    sx={{
                      width: 32,
                      height: 32,
                      backgroundColor: theme.palette.brandColorTwo.main,
                    }}
                  >
                    {user?.first_name && user?.first_name[0]?.toUpperCase()}
                  </Avatar>
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openProfileDropdown}
              onClose={handleProfileDropdownClose}
              onClick={handleProfileDropdownClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={() => {
                  setSelectedItem("profile");
                  handleProfileDropdownClose();
                }}
              >
                {/* <Avatar
                  sx={{ height: "10px", width: "10px", fontSize: "15px" }}
                />{" "} */}
                <ListItemIcon>
                  <User size="20px" />
                </ListItemIcon>

                <Typography variat="p" color={theme.palette.para.main}>
                  {" "}
                  {props?.t("My Profile")}
                </Typography>
              </MenuItem>
              {/* <MenuItem onClick={handleProfileDropdownClose}>
                <Avatar /> My account
              </MenuItem> */}
              <Divider />

              <MenuItem
                onClick={() => {
                  setSelectedItem("account");
                  handleProfileDropdownClose();
                }}
              >
                <ListItemIcon>
                  <Settings size="20px" />
                </ListItemIcon>
                <Typography variat="p" color={theme.palette.para.main}>
                  {props?.t("Settings")}
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  logout();
                  handleProfileDropdownClose();
                }}
              >
                <ListItemIcon>
                  <LogOut size="20px" />
                </ListItemIcon>
                <Typography variat="p" color={theme.palette.para.main}>
                  {props?.t("Logout")}
                </Typography>
              </MenuItem>
              <MenuItem>
                <Switch
                  size="small"
                  checked={mode === "dark"}
                  onChange={handleModeChange}
                />
                <Typography
                  variant="p"
                  sx={{ mx: 1, color: theme.palette.para.main }}
                >
                  Night mode
                </Typography>
              </MenuItem>
            </Menu>
            <Menu
              anchorEl={anchorEl2}
              id="data-menu"
              open={openDataDropdown}
              onClose={handleDataDropdownClose}
              onClick={handleDataDropdownClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem>
                <Link
                  href="https://www.lucuma.io/datenschutz"
                  variat="p"
                  color={theme.palette.para.main}
                  target="_blank"
                >
                  {props?.t("Data Privacy")}
                </Link>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          borderRight: "none",
          // border: "2px solid green",
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        {/********* sidebar in mobile view */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },

            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderWidth: "0",
              borderRight: "none",
              background: theme.palette.background.main,
            },
          }}
        >
          {drawer}
        </Drawer>
        {/******   sidebar in desktop view */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },

            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderWidth: "0",
              borderRight: "none",
              background: theme.palette.background.main,
            },
          }}
          open
        >
          {drawer}
        </Drawer>{" "}
      </Box>
      {/**********************content container ///////////////*/}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          minHeight: "100vh",
          height: "maxContent",
          backgroundColor: theme.palette.secondary.main,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          borderRadius: "10px",
          // border: "3px solid yellow",
        }}
      >
        <Toolbar />
        {/* <DrawerHeader /> */}
        {props?.children(selectedItem)}
      </Box>
    </Box>
  );
}

DashboardLayout.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default withTranslation()(DashboardLayout);
