import React, {useState, useEffect, useCallback} from "react";
import Modal from "@mui/material/Modal";
import {
  Box,
  Grid,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import AuthInput from "../components/InputFields/AuthInput";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTheme } from "@mui/material/styles";
import { Briefcase, MapPin, Home, Map } from "react-feather";
import { api } from "../axios/api";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../actions/userActions";
import { withTranslation } from "react-i18next";

const CompanyFormModal = (props) => {
  let { showModal, setShowCompanyDataUpdateModal, isEditType, companyData } =
    props;
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClose = useCallback(() => {
    if (isEditType) {
      setOpen(false);
      setShowCompanyDataUpdateModal(false);
    }
  });
  useEffect(() => {
    if (showModal) {
      handleOpen();
    } else {
      handleClose();
    }
  }, [handleClose, showModal]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");

  const [companyNameError, setCompanyNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [cityError, setCityError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");

  useEffect(() => {
    if (companyData) {
      setCity(companyData?.city);
      setCompanyName(companyData?.company_name);
      setAddress(companyData?.address);
      setZipCode(companyData?.zip_code);
    }
  }, [companyData]);

  const handleCompanyNameChange = (event) => {
    setCompanyName(event.target.value);
    setCompanyNameError("");
  };

  const handleCityChange = (event) => {
    setCity(event.target.value);
    setCityError("");
  };

  const handleZipCodeChange = (event) => {
    setZipCode(event.target.value);
    setZipCodeError("");
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
    setAddressError("");
  };

  const clearForms = () => {
    setCompanyName("");
    setZipCode("");
    setAddress("");
    setCity("");
  };

  const handleSubmit = () => {
    console.log({
      companyName,
      city,
      address,
      zipCode,
    });

    let obj = {
      company_name: companyName,
      zip_code: zipCode,
      city: city,
      address: address,
    };
    api
      .post("/addCompanyDetails", obj)
      .then((res) => {
        console.log("resposne from adding details user", res.data);

        toast.success("company added successfully!");
        let companyData = res?.data?.companyData;
        let userdata = {
          ...user,
          company_id: companyData?.id,
          company_name: companyData?.company_name,
          city: companyData?.city,
          address: companyData?.address,
          zip_code: companyData?.zip_code,
        };
        console.log("userData", userdata);
        dispatch(setUser(userdata));
        clearForms();
        handleClose();
        setOpen(false);
      })
      .catch((err) => {
        console.log("error in adding company details", err);
        toast.error("registration failed!");
      });
  };
  const handleUpdate = () => {
    console.log({
      companyName,
      city,
      address,
      zipCode,
    });

    let obj = {
      company_name: companyName,
      zip_code: zipCode,
      city: city,
      address: address,
    };
    api
      .put("/updateCompanyDetails/" + user?.company_id, obj)
      .then((res) => {
        console.log("resposne from adding details company", res.data);

        toast.success("company data updated successfully!");
        let companyData = res?.data;
        let userdata = {
          ...user,
          company_id: companyData?.id,
          company_name: companyData?.company_name,
          city: companyData?.city,
          address: companyData?.address,
          zip_code: companyData?.zip_code,
        };
        console.log("userData", userdata);
        dispatch(setUser(userdata));
        clearForms();
        handleClose();
      })
      .catch((err) => {
        console.log("error in updating company details", err);
        toast.error("registration failed!");
      });
  };

  const validateCompanyName = (companyName) => {
    if (!companyName) {
      setCompanyNameError("companyName is required");
      return false;
    }
    setCompanyNameError("");
    return true;
  };

  const validateCity = (city) => {
    if (!city) {
      setCityError("City is required");
      return false;
    }
    setCityError("");
    return true;
  };
  const validateAddress = (address) => {
    if (!address) {
      setAddressError("Address is required");
      return false;
    }
    setAddressError("");
    return true;
  };
  const validateZipCode = (zipCode) => {
    if (!zipCode) {
      setZipCodeError("ZipCode is required");
      return false;
    }
    setZipCodeError("");
    return true;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    console.log("running handle form submit");

    const isCompanyName = validateCompanyName(companyName);
    const isCityValid = validateCity(city);
    const isAddressValid = validateAddress(address);
    const isZipCodeValid = validateZipCode(zipCode);

    if (isCompanyName && isCityValid && isAddressValid && isZipCodeValid) {
      console.log("everything is correct");
      isEditType ? handleUpdate() : handleSubmit();
    } else {
      console.log("error occurs");
    }
  };

  return (
    <Box>
      <ToastContainer />
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xl: "30%", lg: "40%", md: "50%", sm: "60%", xs: "85%" },

            // minWidth: { xs: "300px", sm: "500px" },

            height: "max-content",
            maxHeight: "85vh",

            overflowY: "auto",
            bgcolor: "background.paper",
            padding: { md: "40px 20px 70px 20px", xs: "40px 10px 40px 10px" },
            // backgroundColor: "rgba(255, 255, 255, 0.5)",
            // backdropFilter: " blur(10px)",
            //   border: "2px solid #000",
            //   boxShadow: 24,
          }}
        >
          {/* <Container maxWidth="lg" sx={{ padding: "0px" }}> */}
          <Grid
            container
            spacing={0}
            sx={{
              // backgroundColor: theme.palette.background.main,
              // border: "1px solid #e2e7f1",
              bordeRradius: "0.25rem",
              transition: "all 0.3s ease",
              margin: "0px 0",
            }}
          >
            {/* Left Portion */}

            {/* Right Portion */}
            <Grid item xs={12} sm={12}>
              <Box sx={{ py: 0, px: { xs: 2, sm: 5 } }}>
                {/* <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      textAlign: "center",
                      marginBottom: "30px",
                      color: theme.palette.primary.main,
                      fontWeight: "600",
                    }}
                  >
                    Goods Management
                  </Typography> */}
                <Box
                  sx={{
                    margin: "0px 0 20px 0",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img alt=""
                    src="https://lucumafiles.s3.eu-central-1.amazonaws.com/lucumaLogo.png"
                    style={{ width: "150px" }}
                  />
                </Box>
                <Box sx={{ marginBottom: "30px" }}>
                  {" "}
                  <Typography
                    variant="h5"
                    gutterBottom
                    sx={{
                      fontSize: "0.9rem",
                      fontWeight: "500",
                      color: theme.palette.heading.main,
                    }}
                  >
                    {isEditType
                      ? props?.t("Edit Company Data")
                      : props?.t("Welcome!")}
                  </Typography>
                  {!isEditType && (
                    <Typography
                      variant="p"
                      sx={{
                        fontWeight: "400",
                        fontSize: "0.9rem",
                        color: theme.palette.para.main,
                      }}
                      gutterBottom
                    >
                      {props?.t("We need your company details to proceed")}
                    </Typography>
                  )}
                </Box>

                <Box>
                  <Box>
                    <AuthInput
                      label={props?.t("Company Name")}
                      placeholder={props?.t("Company Name")}
                      value={companyName}
                      onChange={handleCompanyNameChange}
                      icon={
                        <Briefcase
                          size={17}
                          color={theme.palette.menuHeading.main}
                          strokeWidth={1.3}
                        />
                      }
                      error={!!companyNameError}
                      helperText={companyNameError}
                    />
                  </Box>
                  <Box>
                    <AuthInput
                      label={props?.t("Street and House-No.")}
                      placeholder={props?.t("Street and House-No.")}
                      value={address}
                      onChange={handleAddressChange}
                      icon={
                        <Home
                          size={17}
                          color={theme.palette.menuHeading.main}
                          strokeWidth={1.3}
                        />
                      }
                      error={!!addressError}
                      helperText={addressError}
                    />
                  </Box>

                  <Box>
                    <AuthInput
                      label={props?.t("Town/City")}
                      placeholder={props?.t("Town/City")}
                      value={city}
                      onChange={handleCityChange}
                      icon={
                        <MapPin
                          size={17}
                          color={theme.palette.menuHeading.main}
                          strokeWidth={1.3}
                        />
                      }
                      error={!!cityError}
                      helperText={cityError}
                    />
                  </Box>
                  <Box>
                    <AuthInput
                      label={props?.t("ZIP Code")}
                      placeholder={props?.t("ZIP Code")}
                      value={zipCode}
                      onChange={handleZipCodeChange}
                      icon={
                        <Map
                          size={17}
                          color={theme.palette.menuHeading.main}
                          strokeWidth={1.3}
                        />
                      }
                      error={!!zipCodeError}
                      helperText={zipCodeError}
                    />
                  </Box>

                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    sx={{
                      mt: 2,
                      backgroundColor: theme.palette.brandColorOne.main,
                      height: "36px",
                      color: theme.palette.whiteFont.main,
                      marginTop: "20px",
                    }}
                    onClick={handleFormSubmit}
                  >
                    {isEditType ? props?.t("UPDATE") : props?.t("SUBMIT")}
                  </Button>
                  {isEditType && (
                    <Button
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 2,
                        backgroundColor: theme.palette.brandColorOne.main,
                        height: "36px",
                        color: theme.palette.whiteFont.main,
                        marginTop: "10px",
                      }}
                      onClick={handleClose}
                    >
                      {props?.t("CANCEL")}
                    </Button>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default withTranslation()(CompanyFormModal);
