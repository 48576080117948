import axios from "axios";

let baseURL =
  process.env.NODE_ENV === "production"
    ? `${process.env.REACT_APP_API_URL}/api/v1`
    : "http://localhost:9000/api/v1";

const api = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  baseURL,
});

const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common["auth-token"] = token;
  } else {
    delete api.defaults.headers.common["auth-token"];
  }
};

// Set initial token if available
setAuthToken(JSON.parse(localStorage.getItem("token")));

export { api, setAuthToken };
