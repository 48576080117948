import React, { useState, useEffect } from "react";
import { api } from "../axios/api";
import { useSelector } from "react-redux";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import Papa from "papaparse";
import { withTranslation } from "react-i18next";

const StyledTable = styled(TableContainer)`
  max-height: 400px;
  overflow-y: auto;
  overflow-x: auto;
`;

const ImportFile = (props) => {
  const user = useSelector((state) => state.user);
  const [importType, setImportType] = useState("suppliers");
  const [allowedFields, setAllowedFields] = useState({
    suppliers: [
      "Supplier Name",
      "Address",
      "Zip Code",
      "City",
      "County",
      "Company ID",
    ],
    customers: [
      "Customer Name",
      "Address",
      "Zip Code",
      "City",
      "County",
      "Company ID",
    ],
    incomingGoods: [
      "Article No.",
      "Batch No.",
      "Product Description",
      "Amount",
      "Unit",
      "Delivery Date",
      "Expiration Date",
      "Company ID",
      "Supplier ID",
      "Delivery Note",
    ],
    outgoingGoods: [
      "Article No.",
      "Batch No.",
      "Product Description",
      "Amount",
      "Unit",
      "Delivery Date",
      "Expiration Date",
      "Receiving Date",
      "Company ID",
      "Customer ID",
      "Delivery Note",
    ],
  });

  const theme = useTheme();

  const [data, setData] = useState([]);
  const [formData, setFormData] = useState([]);

  const handleRadioChange = (event) => {
    setImportType(event.target.value);
    console.log(event.target.value);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      header: true,
      complete: (result) => {
        setData(result.data);
        setFormData(result.data?.filter((obj) => Object.keys(obj).length >= 2));
      },
    });
  };

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    const newData = [...formData];
    newData[index] = { ...newData[index], [name]: value };
    setFormData(newData);
  };

  useEffect(() => {
    console.log("data", data);
    console.log("form data", formData);
  }, [data, formData]);

  function mapKeys(obj) {
    let keyMap;
    if (importType === "suppliers") {
      keyMap = {
        Address: "address",
        City: "city",
        County: "county",
        "Company ID": "company_id",

        "Supplier Name": "supplier_name",
        "Zip Code": "zip_code",
      };
    } else if (importType === "customers") {
      keyMap = {
        Address: "address",
        City: "city",
        County: "county",
        "Company ID": "company_id",
        "Customer Name": "customer_name",
        "Zip Code": "zip_code",
      };
    } else if (importType === "incomingGoods") {
      keyMap = {
        "Article No.": "article_no",
        "Batch No.": "batch_no",
        "Product Description": "product_description",
        Amount: "amount",
        Unit: "unit",
        "Delivery Date": "delivery_date",
        "Expiration Date": "expiration_date",
        "Company ID": "company_id",
        "Supplier ID": "supplier_id",
        "Delivery Note": "delivery_note",
      };
    } else if (importType === "outgoingGoods") {
      keyMap = {
        "Article No.": "article_no",
        "Batch No.": "batch_no",
        "Product Description": "product_description",
        Amount: "amount",
        Unit: "unit",
        "Delivery Date": "delivery_date",
        "Expiration Date": "expiration_date",
        "Receiving Date": "receiving_date",
        "Company ID": "company_id",
        "Customer ID": "customer_id",
        "Delivery Note": "delivery_note",
      };
    }

    const newObj = {};
    for (const key in obj) {
      if (keyMap[key]) {
        newObj[keyMap[key]] = obj[key];
      } else {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  }

  function checkKeys(keys) {
    // Get the allowed fields for the current import type
    console.log("allowedFields[importType]", allowedFields[importType]);
    const currentAllowedFields = allowedFields[importType];

    // Check if all keys are included in the allowed fields
    // return keys.every((key) => currentAllowedFields.includes(key));
    return currentAllowedFields.every((field) => keys.includes(field));
  }
  const submitInputFileData = async () => {
    console.log("formData", formData);
    if (FormData?.length > 0) {
      let keys = Object.keys(formData[0]);
      console.log("keys", keys);
      const areAllKeysValid = checkKeys(keys);
      console.log(areAllKeysValid); // true
      if (areAllKeysValid) {
        let updatedArrayOfObjects = formData.map((obj) => mapKeys(obj));
        console.log("updated final object", updatedArrayOfObjects);
        let apiPath =
          importType === "suppliers"
            ? `/postSupplier`
            : importType === "customers"
            ? `/postCustomer`
            : importType === "incomingGoods"
            ? `/postIncomingGoods`
            : importType === "incomingGoods" && `/postIncomingGoods`;
        updatedArrayOfObjects?.map(async (obj, i) => {
          api
            .post(apiPath, obj)
            .then((res) => {
              console.log(
                "response from adding supplier entry" + i + 1,

                res?.data
              );
              toast.success("successfully added supplier entry" + i + 1);
            })
            .catch((err) => {
              console.log("err in adding supplier entry " + i + 1, err);
              toast.error("error in adding supplier entry" + i + 1);
            });
        });
      } else {
        toast?.error("File Data is not valid as suppliers !");
      }
      setFormData([]);
      setData([]);
    }
  };

  return (
    <Box>
      <ToastContainer />
      {/* <Box onClick={() => exportFile()}>Click to export Suppliers File</Box> */}
      <Typography
        variant="h6"
        sx={{
          color: theme.palette.heading.main,
          fontSize: "1.3rem",
          fontWeight: "600",
        }}
      >
        {props.t("Import CSV Files")}
      </Typography>
      <Box
        sx={{
          padding: "30px",
          backgroundColor: theme.palette.background.main,
          borderRadius: "4px",
          marginTop: "30px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="body2" sx={{ marginBottom: "20px" }}>
          Imports contacts and shipments from CSV file.
        </Typography>
        <>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Select an option to import:
            </FormLabel>
            <RadioGroup
              aria-label="options"
              name="options"
              value={importType}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="suppliers"
                control={<Radio />}
                label="Suppliers"
              />
              <FormControlLabel
                value="customers"
                control={<Radio />}
                label="Customers"
              />
              <FormControlLabel
                value="incomingGoods"
                control={<Radio />}
                label="Incoming Goods"
              />
              <FormControlLabel
                value="outgoingGoods"
                control={<Radio />}
                label="Outgoing Goods"
              />
            </RadioGroup>
          </FormControl>
          <Typography variant="body2" sx={{ marginTop: "30px" }}>
            {importType === "suppliers"
              ? "Expected Columns in CSV Flile. (Supplier Name, Address, Zip Code, City, County and  Company ID)"
              : importType === "customers"
              ? "Expected Columns in CSV Flile. (Customer Name, Address, Zip Code, City, County and  Company ID)"
              : importType === "incomingGoods"
              ? "Expected Columns in CSV Flile. (ID, Article No.,Batch No., Product Description, Amount, Unit, Delivery Date, Expiration Date, Company ID, Supplier ID and Delivery Note)"
              : importType === "outgoingGoods"
              ? "Expected Columns in CSV Flile. (ID, Article No.,Batch No., Product Description, Amount, Unit, Delivery Date, Expiration Date, Receiving Date, Company ID, Customer ID and Delivery Note)"
              : "else"}
          </Typography>
          <Typography
            variant="body2"
            sx={{ marginTop: "20px", color: theme.palette.para.main }}
          >
            Select CSV file
          </Typography>
          <Box
            sx={{
              margin: "10px 0 25px 0",
              border: "1px solid lightgray",
              padding: "12px 5px",
              width: "max-content",
            }}
          >
            <input type="file" onChange={handleFileUpload} />
          </Box>

          <StyledTable>
            <Table>
              <TableHead>
                <TableRow>
                  {data.length > 0 &&
                    Object.keys(data[0]).map((key) => (
                      <TableCell key={key}>{key}</TableCell>
                    ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {formData.length > 0 &&
                  formData.map((row, index) => (
                    <TableRow key={index}>
                      {Object.keys(row).map((key) => (
                        <TableCell key={key}>
                          <TextField
                            name={key}
                            value={formData[index][key]}
                            onChange={(e) => handleInputChange(e, index)}
                            style={{ width: "160px" }}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </StyledTable>

          <Button
            variant="contained"
            sx={{
              marginTop: "20px",
              width: "max-content",
              color: theme.palette.whiteFont.main,
            }}
            onClick={() => submitInputFileData()}
          >
            Save
          </Button>
        </>
      </Box>
    </Box>
  );
};

export default withTranslation()(ImportFile);
