import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import {TRIGGER_REFRESH} from "../actions/userActions";

const initialState = {
  refreshTrigger: 0,
  user: localStorage.getItem("lucuma_user")
    ? JSON.parse(localStorage.getItem("lucuma_user"))
    : null,
  token: localStorage.getItem("lucuma_token")
    ? JSON.parse(localStorage.getItem("lucuma_token"))
    : null,
  viewMode: localStorage.getItem("lucuma_view_mode")
    ? JSON.parse(localStorage.getItem("lucuma_view_mode"))
    : "light", //either light or dark
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "SET_Token":
      return {
        ...state,
        token: action.payload,
      };
    case "SET_VIEW_MODE":
      return {
        ...state,
        viewMode: action.payload,
      };
    case TRIGGER_REFRESH:
      return {
        ...state,
        refreshTrigger: state.refreshTrigger + 1,
      };
    default:
      return state;
  }
}

const store = createStore(reducer, applyMiddleware(thunk));

export default store;
