import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import {
    TextField,
    Button,
    Grid,
    Box,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Typography,
    FormHelperText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { api } from "../axios/api";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { withTranslation } from "react-i18next";
import dayjs from "dayjs";

const validationSchema = Yup.object().shape({
  article_no: Yup.string(),
  batch_no: Yup.string(),
  product_description: Yup.string().required("Required"),
  product_category: Yup.string(),
  amount: Yup.number().required("Required"),
  unit: Yup.string().required("Required"),
  delivered_on: Yup.date().default(() => new Date()),
  expiration_date: Yup.date().default(() => new Date()),
  arrived_on: Yup.date().default(() => new Date()),
  customer_id: Yup.string().required("Required"),
  note: Yup.string(),
});
const dateConversion = (dateString) => {
    if (!dateString) {
        return "";
    }
    const date = dateString.indexOf("T") > -1 ? dayjs(dateString) : dayjs(dateString,  "DD.MM.YYYY");
    return date.format("YYYY-MM-DD");
};

const AddOutgoingGoodsForm = (props) => {
  let { editOptions, setShowFormModal, nextStep } = props;
  const theme = useTheme();

  const [initialValues, setInitialValues] = useState(
    editOptions
      ? {
          article_no: editOptions?.article_no,
          batch_no: editOptions?.batch_no,
          product_description: editOptions?.product_description,
          product_category: editOptions?.product_category ?? "",
          amount: editOptions?.amount,
          unit: editOptions?.unit ?? "Stk",
          delivered_on: dateConversion(editOptions?.delivered_on ? editOptions?.delivered_on : (new Date()).toISOString()),
          expiration_date: dateConversion(editOptions?.expiration_date ? editOptions?.expiration_date : (new Date()).toISOString()),
          arrived_on: dateConversion(editOptions?.arrived_on ? editOptions?.arrived_on : (new Date()).toISOString()),
          customer_id: editOptions?.customer_id,
          note: editOptions?.delivery_note_no,
        }
      : {
          article_no: "",
          batch_no: "",
          product_description: "",
          product_category: "",
          amount: "",
          unit: "Stk",
          delivered_on: dateConversion((new Date()).toISOString()),
          expiration_date: dateConversion((new Date()).toISOString()),
          arrived_on: dateConversion((new Date()).toISOString()),
          customer_id: "",
          note: "",
        }
  );
  const [customers, setCustomers] = useState([]);
  const user = useSelector((state) => state.user);
    useEffect(() => {
        console.log("edit options at outgoing goods", editOptions);
    }, [editOptions]);
  useEffect(() => {
    console.log("user at add contact", user);
  }, [user]);

  useEffect(() => {
    let body = {
      company_id: user?.company_id,
    };
    if (user?.company_id) {
      console.log("body", body);
      api
        .post("/getCustomers", body)
        .then((res) => {
          console.log("response from getting customers", res?.data);
          setCustomers(res?.data);
        })
        .catch((err) => console.log("err", err));
    }
  }, [user]);

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    if (editOptions?.id) {
      let body = {
        article_no: values?.article_no,
        batch_no: values?.batch_no,
        product_description: values?.product_description,
        product_category: values?.product_category,
        amount: values?.amount,
        unit: values?.unit,
        delivered_on: values?.delivered_on,
        arrived_on: values?.arrived_on,
        expiration_date: values?.expiration_date,
        customer_id: values?.customer_id,
        note: values?.note,
        company_id: user?.company_id,
      };
      console.log("body", body);
      api
        .put("/updateOutgoingGoods/" + editOptions?.id, body)
        .then((res) => {
          console.log("response from posting content", res?.data);
          toast.success("good updated successfully!");
          setShowFormModal(false);
          resetForm();
          if (nextStep) nextStep();
        })
        .catch((err) => {
          console.log("err", err);
          toast.error("error in updating good");
        });
    } else {
      let body = {
        article_no: values?.article_no,
        batch_no: values?.batch_no,
        product_description: values?.product_description,
        product_category: values?.product_category,
        amount: values?.amount,
        unit: values?.unit,
        delivered_on: values?.delivered_on,
        arrived_on: values?.arrived_on,
        expiration_date: values?.expiration_date,
        customer_id: values?.customer_id,
        note: values?.note,
        company_id: user?.company_id,
      };
      console.log("body", body);
      api
        .post("/postOutgoingGoods", body)
        .then((res) => {
          console.log("response from posting content", res?.data);
          toast.success("Outgoing Good added successfully!");
          resetForm();
          setTimeout(() => {
              setShowFormModal(false);
              if (nextStep) nextStep();
          }, 1500);
        })
        .catch((err) => {
          console.log("err", err);
          toast.error(
            err?.response?.data?.error ?? "Error adding incoming good"
          );
        });
    }
  };

  const cancelForm = () => {
      setShowFormModal(false);
      if (nextStep) nextStep();
  };

  return (
    <Box sx={{ padding: "30px 0px" }}>
      <ToastContainer />
      <Box
        sx={{
          backgroundColor: theme.palette.background.main,
          padding: "20px",
          borderRadius: "5px",
          marginTop: "20px",
          maxWidth: "700px",
        }}
      >
        <Typography
          variant="body2"
          sx={{ margin: "10px 0 30px 0", color: theme.palette.para.main }}
        >
          {props?.t("Fill up the form with Supplier or Consignment details")}.
        </Typography>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, touched }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    name="article_no"
                    label={props?.t("Article No.")}
                    as={TextField}
                    error={touched.article_no && Boolean(errors.article_no)}
                    helperText={touched.article_no && errors.article_no}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    name="batch_no"
                    label={props?.t("Batch No.")}
                    as={TextField}
                    error={touched.batch_no && Boolean(errors.batch_no)}
                    helperText={touched.batch_no && errors.batch_no}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="product_description"
                    label={props?.t("Product Description")}
                    as={TextField}
                    error={
                      touched.product_description &&
                      Boolean(errors.product_description)
                    }
                    helperText={
                      touched.product_description && errors.product_description
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    name="product_category"
                    label={props?.t("Product Category")}
                    as={TextField}
                    error={
                      touched.product_category &&
                      Boolean(errors.product_category)
                    }
                    helperText={
                      touched.product_category && errors.product_category
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    name="amount"
                    label={props?.t("Amount")}
                    type="number"
                    as={TextField}
                    error={touched.amount && Boolean(errors.amount)}
                    helperText={touched.amount && errors.amount}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={touched.unit && Boolean(errors.unit)}
                  >
                    <InputLabel id="supplier-select-label">
                      {props?.t("Unit")}
                    </InputLabel>
                    <Field
                      as={Select}
                      labelId="supplier-select-label"
                      name="unit"
                      label={props?.t("Unit")}
                    >
                      <MenuItem key="Stk" value="Stk">
                        Stk (Stück)
                      </MenuItem>
                      <MenuItem key="g" value="g">
                        g (gram)
                      </MenuItem>
                      <MenuItem key="kg" value="kg">
                        kg (Kilogram)
                      </MenuItem>
                      <MenuItem key="t" value="t">
                        t (tonne)
                      </MenuItem>
                      <MenuItem key="l" value="l">
                        l (litre)
                      </MenuItem>
                    </Field>
                    {touched.unit && errors.unit && (
                      <FormHelperText>{errors.unit}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    name="delivered_on"
                    label={props?.t("Delivered On")}
                    type="date"
                    as={TextField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={touched.delivered_on && Boolean(errors.delivered_on)}
                    helperText={touched.delivered_on && errors.delivered_on}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    name="expiration_date"
                    label={props?.t("Expiration Date")}
                    type="date"
                    as={TextField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={
                      touched.expiration_date && Boolean(errors.expiration_date)
                    }
                    helperText={
                      touched.expiration_date && errors.expiration_date
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    name="arrived_on"
                    label={props?.t("Arrived On")}
                    type="date"
                    as={TextField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={touched.arrived_on && Boolean(errors.arrived_on)}
                    helperText={touched.arrived_on && errors.arrived_on}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    error={touched.customer_id && Boolean(errors.customer_id)}
                  >
                    <InputLabel id="customer-select-label">
                      {props?.t("Customer")}
                    </InputLabel>
                    <Field
                      as={Select}
                      labelId="customer-select-label"
                      name="customer_id"
                      label={props?.t("customer ID")}
                    >
                      {customers?.map((customer) => (
                        <MenuItem key={customer?.id} value={customer?.id}>
                          {customer?.trade_name}
                        </MenuItem>
                      ))}
                    </Field>
                    {touched.customer_id && errors.customer_id && (
                      <FormHelperText>{errors.customer_id}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="note"
                    label={props?.t("Note")}
                    as={TextField}
                    error={touched.note && Boolean(errors.note)}
                    helperText={touched.note && errors.note}
                  />
                </Grid>
              </Grid>
              <Box sx={{ display: "flex", gap: "5px", marginTop: "10px" }}>
                <Button
                  variant="contained"
                  // color={theme.palette.brandColorOne.main}
                  sx={{
                    backgroundColor: theme.palette.brandColorOne.main,
                    color: theme.palette.whiteFont.main,
                  }}
                  type="submit"
                  size="small"
                >
                  {editOptions?.id ? props?.t("Update") : props?.t("SAVE")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => cancelForm()}
                  sx={{
                    marginLeft: "10px",
                    backgroundColor: theme.palette.brandColorOne.main,
                    color: theme.palette.whiteFont.main,
                  }}
                  size="small"
                >
                  {props?.t("Cancel")}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default withTranslation()(AddOutgoingGoodsForm);
