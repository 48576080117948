import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import AuthInput from "../components/InputFields/AuthInput";
import { Link, useNavigate } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { useTheme } from "@mui/material/styles";
import { User, Mail, Lock } from "react-feather";
import AES from "crypto-js/aes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { api, setAuthToken } from "../axios/api";
import { useDispatch } from "react-redux";
import { setToken } from "../actions/userActions";

const Login = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    let token = localStorage.getItem("lucuma_token");
    token = JSON.parse(token);
    if (token) {
      dispatch(setToken(token));
      navigate("/dashboard");
    }
  }, [dispatch, navigate]);
  const theme = useTheme();
  const [mode, setMode] = useState("login");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [error, setError] = useState("");
  const [showNotVerifiedStatus, setShowNotVerifiedStatus] = useState(false);
  const [isVerificationEmailSent, setIsVerificationEmailSent] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(false);
  const [isAgreeToTermsAndConditions, setIsAgreeToTermAndConditions] =
    useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError("");
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError("");
  };

  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
    setFirstNameError("");
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
    setLastNameError("");
  };

  const clearForms = () => {
    setEmail("");
    setFirstName("");
    setLastName("");
    setPassword("");
  };
  const sendAccountVerificationEmail = () => {
    api
      .post("/sendAccountVerificationEmail")
      .then((res) => {
        console.log("res?.data from sendAccountVerificationEmail", res?.data);
        setIsVerificationEmailSent(true);
        setTimeout(() => {
          setShowNotVerifiedStatus(false);
        }, 4000);
        clearForms();
      })
      .catch((err) => {
        console.log(err);
        toast.error("unable to send email");
      });
  };

  const handleSubmit = () => {
    console.log({
      mode,
      email,
      password,
      firstName,
      lastName,
    });
    console.log("password", password);
    console.log(
      " process.env.REACT_APP_CRYPTO_JS_KEY",
      process.env.REACT_APP_CRYPTO_JS_KEY
    );
    let encPass = AES.encrypt(
      password,
      process.env.REACT_APP_CRYPTO_JS_KEY
    ).toString();
    console.log("encPass", encPass);

    if (mode === "signup") {
      let obj = {
        first_name: firstName,
        last_name: lastName,
        email_address: email,
        password: encPass,
      };
      api
        .post("/register", obj)
        .then((res) => {
          console.log("resposne from registering user", res.data);
          toast.success("Verification email has been sent to your email!");
          clearForms();
        })
        .catch((err) => {
          console.log("error in registering user", err);
          toast.error("registration failed!");
        });
    } else if (mode === "login") {
      let obj = {
        email_address: email,
        password: encPass,
      };
      api
        .post("/signin", obj)
        .then((res) => {
          console.log("resposne from logging user", res.data);
          const { verified, token } = res?.data;

          setAuthToken(token);
          if (verified) {
            dispatch(setToken(token));
            toast.success("logged in successfully!");
            clearForms();
            setTimeout(() => {
              navigate("/dashboard");
            }, 2000);
          } else {
            setShowNotVerifiedStatus(true);
          }
        })
        .catch((err) => {
          console.log("error in logging user", err);
          if (err?.response?.status === 404) {
            toast.error("User not found!");
          } else {
            toast.error("Login with correct credentials!");
          }
        });
    } else if (mode === "forgot") {
      let obj = {
        email_address: email,
      };
      clearForms();
      api
        .post("/sendpasswordrecoverylink", obj)
        .then((res) => {
          console.log("responsefrom sending email recovery link", res.data);
          toast.success("email with instructions has been sent!");
          clearForms();
        })
        .catch((err) => {
          console.log("error", err);
          toast.error("unable to send recovery link!");
        });
    }
  };
  useEffect(() => {
    console.log("error", error);
  }, [error]);

  const validateEmail = (email) => {
    if (!email) {
      setEmailError("Email is required");
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.match(emailRegex)) {
      setEmailError("Invalid email");
      return false;
    }
    setEmailError("");
    return true;
  };
  const validatePassword = (password) => {
    if (!password) {
      setPasswordError("Password is required");
      return false;
    }
    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters long");
      return false;
    }
    setPasswordError("");
    return true;
  };

  const validateFirstName = (firstName) => {
    if (!firstName) {
      setFirstNameError("First name is required");
      return false;
    }
    setFirstNameError("");
    return true;
  };

  const validateLastName = (lastName) => {
    if (!lastName) {
      setLastNameError("Last name is required");
      return false;
    }
    setLastNameError("");
    return true;
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    console.log("running handle form submit");
    if (mode === "signup") {
      const isEmailValid = validateEmail(email);
      const isPasswordValid = validatePassword(password);
      const isFirstNameValid = validateFirstName(firstName);
      const isLastNameValid = validateLastName(lastName);

      if (
        isEmailValid &&
        isPasswordValid &&
        isFirstNameValid &&
        isLastNameValid
      ) {
        console.log("everything is correct");
        if (!isAgreeToTermsAndConditions) {
          toast.error("You have not agreed to our Terms & Conditions");
        } else {
          handleSubmit();
        }
      } else {
        console.log("error occurs");
      }
    } else if (mode === "login") {
      const isEmailValid = validateEmail(email);
      const isPasswordValid = validatePassword(password);
      if (isEmailValid && isPasswordValid) {
        handleSubmit();
      }
    } else {
      const isEmailValid = validateEmail(email);

      if (isEmailValid) {
        handleSubmit();
      }
    }
  };

  useEffect(() => {
    console.log("isRememberMe", isRememberMe);
    console.log("isAgreeToTermsAndConditions", isAgreeToTermsAndConditions);
  }, [isRememberMe, isAgreeToTermsAndConditions]);
  return (
    <Box
      sx={{
        bgcolor: theme.palette.secondary.main,
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <ToastContainer />
      <Container
        maxWidth="lg"
        sx={{
          padding: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          spacing={0}
          sx={{
            backgroundColor: theme.palette.background.main,
            bordeRradius: "0.25rem",
            transition: "all 0.3s ease",
            margin: "15px 0 30px 0",
            maxWidth: "500px",
          }}
        >
          {/* Left Portion */}

          {/* Right Portion */}
          <Grid item xs={12} sm={12}>
            <Box sx={{ padding: "30px 0 50px 0", px: { xs: 2, sm: 10 } }}>
              <Box
                sx={{
                  marginBottom: "50px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img alt = ""
                  src="https://lucumafiles.s3.eu-central-1.amazonaws.com/lucumaLogo.png"
                  style={{ width: "150px" }}
                />
              </Box>
              <Box sx={{ marginBottom: "30px" }}>
                {" "}
                <Typography
                  variant="h5"
                  // gutterBottom
                  sx={{
                    fontSize: "0.9rem",
                    fontWeight: "500",
                    color: theme.palette.heading.main,
                  }}
                >
                  {mode === "login"
                    ? props?.t("Welcome back!")
                    : mode === "forgot"
                    ? props?.t("Reset Password")
                    : props?.t("Create Account")}
                </Typography>
                <Typography
                  variant="p"
                  sx={{
                    fontWeight: "400",
                    fontSize: "0.9rem",
                    color: theme.palette.para.main,
                  }}
                  gutterBottom
                >
                  {mode === "login"
                    ? props?.t("Enter your email and password for login")
                    : mode === "forgot"
                    ? props?.t(
                        "Enter your email address and we'll send you an email with instructions to reset your password"
                      )
                    : props?.t(
                        "Create account and get access to our amazing services"
                      )}
                </Typography>
              </Box>
              {showNotVerifiedStatus && (
                <>
                  {isVerificationEmailSent ? (
                    <Box
                      sx={{
                        backgroundColor: "#00ff0011",
                        padding: "10px 10px",
                      }}
                    >
                      <Typography variant="p">
                        {props?.t("Verification Email Sent")}
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        backgroundColor: "#ff000011",
                        padding: "10px 10px",
                      }}
                    >
                      <Typography variant="p">
                        {props?.t(
                          "Your Account is not verified. We have already sent you an email with instructions"
                        )}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          padding: "8px 14px",
                          border: "1px solid lightgray",
                          cursor: "pointer",
                          marginTop: "10px",
                          width: "max-content",
                          backgroundColor: theme.palette.brandColorThree.main,
                        }}
                        onClick={() => sendAccountVerificationEmail()}
                      >
                        {props?.t("Resend Email")}
                      </Typography>
                    </Box>
                  )}
                </>
              )}

              <Box>
                {mode === "signup" && (
                    <Box
                        sx={{display: "flex", gap: "10px", marginTop: "-25px"}}
                    >
                      <AuthInput
                          label={props?.t("First Name")}
                          placeholder={props?.t("First Name")}
                          value={firstName}
                          onChange={handleFirstNameChange}
                          icon={
                            <User
                                size={17}
                                color={theme.palette.menuHeading.main}
                                strokeWidth={1.3}
                            />
                          }
                          error={firstNameError ? true : false}
                          helperText={firstNameError}
                      />
                      <AuthInput
                          label={props?.t("Last Name")}
                          placeholder={props?.t("Last Name")}
                          value={lastName}
                          onChange={handleLastNameChange}
                          icon={
                            <User
                                size={17}
                                color={theme.palette.menuHeading.main}
                                strokeWidth={1.3}
                            />
                          }
                          error={lastNameError ? true : false}
                          helperText={lastNameError}
                      />
                    </Box>
                )}

                <Box>
                  <AuthInput
                    label={props?.t("Email Address")}
                    placeholder={props?.t("Email")}
                    value={email}
                    onChange={handleEmailChange}
                    icon={
                      <Mail
                        size={17}
                        color={theme.palette.menuHeading.main}
                        strokeWidth={1.3}
                      />
                    }
                    error={emailError ? true : false}
                    helperText={emailError}
                  />
                </Box>

                {mode !== "forgot" && (
                  <Box>
                    <AuthInput
                      isPassword={true}
                      label={props?.t("Password")}
                      placeholder={props?.t("Password")}
                      value={password}
                      onChange={handlePasswordChange}
                      icon={
                        <Lock
                          size={17}
                          color={theme.palette.menuHeading.main}
                          strokeWidth={1.3}
                        />
                      }
                      error={passwordError ? true : false}
                      helperText={passwordError}
                    />
                  </Box>
                )}

                <Box sx={{ marginTop: "20px" }}>
                  {mode === "login" && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={isRememberMe ? true : false}
                          size="small"
                          onChange={() => setIsRememberMe(!isRememberMe)}
                        />
                      }
                      label={
                        <Typography
                          color={theme.palette.para.main}
                          sx={{ fontSize: "0.8rem", marginTop: "3px" }}
                        >
                          {props?.t("Remember Me")}
                        </Typography>
                      }
                    />
                  )}{" "}
                  {mode === "signup" && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={isAgreeToTermsAndConditions ? true : false}
                          size="small"
                          onChange={() =>
                            setIsAgreeToTermAndConditions(
                              !isAgreeToTermsAndConditions
                            )
                          }
                        />
                      }
                      label={
                        <Typography
                          color={theme.palette.para.main}
                          sx={{ fontSize: "0.8rem", marginTop: "0px" }}
                        >
                          {props?.t("I agree to Terms & Conditions")}
                        </Typography>
                      }
                    />
                  )}
                </Box>

                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{
                    mt: 1,
                    backgroundColor: theme.palette.brandColorOne.main,
                    color: theme.palette.whiteFont.main,
                    height: "36px",
                  }}
                  onClick={handleFormSubmit}
                >
                  {mode === "login"
                    ? props?.t("Login")
                    : mode === "forgot"
                    ? props?.t("Send")
                    : props?.t("Sign Up")}
                </Button>
                {mode === "login" && (
                  <Box sx={{ margin: "35px 0 0px 0" }}>
                    {" "}
                    <Typography
                      color={theme.palette.para.main}
                      sx={{ fontSize: "0.8rem", marginTop: "0px" }}
                    >
                      {props?.t("Forgot Password")}?
                      <Link href="#" onClick={() => setMode("forgot")}>
                        {props?.t(" Reset")}
                      </Link>
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default withTranslation()(Login);
