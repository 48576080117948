import { useEffect } from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ShowCustomers from "../components/ShowCustomers";
import ShowSuppliers from "../components/ShowSuppliers";
import ShowIncomingGoods from "../components/ShowIncomingGoods";
import ShowOutgoingGoods from "../components/ShowOutgoingGoods";
import ImportFile from "../components/ImportFile";
import ExportFile from "../components/ExportFile";
import Account from "../components/Account";
import Profile from "../components/Profile";
import ImportDeliveryNotes from "../components/ImportDeliveryNotes";
import AddUsers from "../components/AddUsers";

const DashboardContent = ({ selectedItem }) => {
  useTheme();
  useEffect(() => {
    console.log("selected item at dashboardContent", selectedItem);
  }, [selectedItem]);
  return (
    <Box>
      {selectedItem === "addUsers" && <AddUsers />}
      {selectedItem === "suppliers" && <ShowSuppliers />}
      {selectedItem === "customers" && <ShowCustomers />}
      {selectedItem === "incomingGoods" && <ShowIncomingGoods />}
      {selectedItem === "outgoingGoods" && <ShowOutgoingGoods />}
      {selectedItem === "importFile" && <ImportFile />}
      {selectedItem === "exportFile" && <ExportFile />}
      {selectedItem === "account" && <Account />}
      {selectedItem === "profile" && <Profile />}
      {selectedItem === "importDeliveryNotes" && <ImportDeliveryNotes />}
    </Box>
  );
};

export default DashboardContent;
