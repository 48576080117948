import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { TextField, Button, Grid, Box } from "@mui/material";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { api } from "../axios/api";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { withTranslation } from "react-i18next";
import dayjs from "dayjs";

const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const validationSchema = Yup.object().shape({
  article_no: Yup.string(),
  batch_no: Yup.string(),
  product_description: Yup.string().required("Required"),
  product_category: Yup.string(),
  received_amount: Yup.number().required("Required"),
  unit: Yup.string().required("Required"),
  received_on: Yup.date().default(() => new Date()),
  expiration_date: Yup.date().default(() => new Date()),
  sent_on: Yup.date().default(() => new Date()),
  supplier_id: Yup.string().required("Required"),
  note: Yup.string(),
});
const dateConversion = (dateString) => {
  if (!dateString) {
    return "";
  }
  const date = dateString.indexOf("T") > -1 ? dayjs(dateString) : dayjs(dateString,  "DD.MM.YYYY");
  return date.format("YYYY-MM-DD");
};
const AddIncomingGoodsForm = (props) => {
  let { editOptions, setShowFormModal, nextStep } = props;

  const theme = useTheme();

  const [initialValues, setInitialValues] = useState(
    editOptions
      ? {
          article_no: editOptions?.article_no,
          batch_no: editOptions?.batch_no,
          product_description: editOptions?.product_description,
          product_category: editOptions?.product_category ?? "",
          received_amount: editOptions?.received_amount,
          unit: editOptions?.unit ?? "Stk",
          received_on: dateConversion(editOptions?.received_on ? editOptions?.received_on : (new Date()).toISOString()),
          expiration_date: dateConversion(editOptions?.expiration_date ?editOptions?.expiration_date : (new Date()).toISOString()),
          sent_on: dateConversion(editOptions?.sent_on ? editOptions?.sent_on : (new Date()).toISOString()),
          supplier_id: editOptions?.supplier_id,
          note: editOptions?.delivery_note_no,
        }
      : {
          article_no: "",
          batch_no: "",
          product_description: "",
          product_category: "",
          received_amount: "",
          unit: "Stk",
          received_on: dateConversion((new Date()).toISOString()),
          expiration_date: dateConversion((new Date()).toISOString()),
          sent_on: dateConversion((new Date()).toISOString()),
          supplier_id: "",
          note: "",
        }
  );

  const [suppliers, setSuppliers] = useState([]);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    console.log("edit options at incoming goods", editOptions);
  }, [editOptions]);
  useEffect(() => {
    console.log("user at add contact", user);
  }, [user]);

  useEffect(() => {
    let body = {
      company_id: user?.company_id,
    };
    if (user?.company_id) {
      console.log("body", body);
      api
        .post("/getSuppliers", body)
        .then((res) => {
          console.log("response from getting suppliers", res?.data);
          setSuppliers(res?.data);
        })
        .catch((err) => console.log("err", err));
    }
  }, [user]);

  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    if (editOptions?.id) {
      let body = {
        article_no: values?.article_no,
        batch_no: values?.batch_no,
        product_description: values?.product_description,
        product_category: values?.product_category,
        received_amount: values?.received_amount,
        unit: values?.unit,
        received_on: values?.received_on,
        expiration_date: values?.expiration_date,
        sent_on: values?.sent_on,
        supplier_id: values?.supplier_id,
        note: values?.note,
        company_id: user?.company_id,
      };
      console.log("body", body);
      api
        .put("/updateIncomingGoods/" + editOptions?.id, body)
        .then((res) => {
          console.log("response from posting content", res?.data);
          toast.success("good updated successfully!");
          setShowFormModal(false);
          resetForm();
          if (nextStep) nextStep();
        })
        .catch((err) => {
          console.log("err", err);
          toast.error("error in updating good");
        });
    } else {
      let body = {
        article_no: values?.article_no,
        batch_no: values?.batch_no,
        product_description: values?.product_description,
        product_category: values?.product_category,
        received_amount: values?.received_amount,
        unit: values?.unit,
        received_on: values?.received_on,
        expiration_date: values?.expiration_date,
        sent_on: values?.sent_on,
        supplier_id: values?.supplier_id,
        note: values?.note,
        company_id: user?.company_id,
      };
      console.log("body", body);
      api
        .post("/postIncomingGoods", body)
        .then((res) => {
          console.log("response from posting content", res?.data);
          toast.success("Incoming goods added successfully!");
          resetForm();
          setTimeout(() => {
            setShowFormModal(false);
            if (nextStep) nextStep();
          }, 1500);
        })
        .catch((err) => {
          console.log("err", err);
          toast.error(
            err?.response?.data?.error ?? "Error adding incoming good"
          );
        });
    }
  };
  const cancelForm = () => {
    setShowFormModal(false);
    if (nextStep) nextStep();
  };

  return (
    <Box
      sx={{ padding: "30px 0px", }}
    >
      <ToastContainer />
      <Box
        sx={{
          backgroundColor: theme.palette.background.main,
          padding: "20px",
          borderRadius: "5px",
          marginTop: "20px",
          maxWidth: "700px",
        }}
      >
        <Typography
          variant="body2"
          sx={{ margin: "10px 0 30px 0", color: theme.palette.para.main }}
        >
          {props?.t("Check and fill the form with the consignment details")}
        </Typography>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, touched }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    name="article_no"
                    label={props?.t("Article No.")}
                    as={TextField}
                    error={touched.article_no && Boolean(errors.article_no)}
                    helperText={touched.article_no && errors.article_no}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    name="batch_no"
                    label={props?.t("Batch No.")}
                    as={TextField}
                    error={touched.batch_no && Boolean(errors.batch_no)}
                    helperText={touched.batch_no && errors.batch_no}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="product_description"
                    label={props?.t("Product Description")}
                    as={TextField}
                    error={
                      touched.product_description &&
                      Boolean(errors.product_description)
                    }
                    helperText={
                      touched.product_description && errors.product_description
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    name="received_amount"
                    label={props?.t("Received Amount")}
                    type="number"
                    as={TextField}
                    error={
                      touched.received_amount && Boolean(errors.received_amount)
                    }
                    helperText={
                      touched.received_amount && errors.received_amount
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={touched.unit && Boolean(errors.unit)}
                  >
                    <InputLabel id="supplier-select-label">
                      {props?.t("Unit")}
                    </InputLabel>
                    <Field
                      as={Select}
                      labelId="supplier-select-label"
                      name="unit"
                      label={props?.t("Unit")}
                    >
                      <MenuItem key="Stk" value="Stk">
                        Stk (Stück)
                      </MenuItem>
                      <MenuItem key="g" value="g">
                        g (gram)
                      </MenuItem>
                      <MenuItem key="kg" value="kg">
                        kg (Kilogram)
                      </MenuItem>
                      <MenuItem key="t" value="t">
                        t (tonne)
                      </MenuItem>
                      <MenuItem key="l" value="l">
                        l (litre)
                      </MenuItem>
                    </Field>
                    {touched.unit && errors.unit && (
                      <FormHelperText>{errors.unit}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    name="product_category"
                    label={props?.t("Product Category")}
                    as={TextField}
                    error={
                      touched.product_category &&
                      Boolean(errors.product_category)
                    }
                    helperText={
                      touched.product_category && errors.product_category
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    name="sent_on"
                    label={props?.t("Sent On")}
                    type="date"
                    as={TextField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={touched.sent_on && Boolean(errors.sent_on)}
                    helperText={touched.sent_on && errors.sent_on}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    name="received_on"
                    label={props?.t("Received On")}
                    type="date"
                    as={TextField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={touched.received_on && Boolean(errors.received_on)}
                    helperText={touched.received_on && errors.received_on}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    fullWidth
                    name="expiration_date"
                    label={props?.t("Expiration Date")}
                    type="date"
                    as={TextField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={
                      touched.expiration_date && Boolean(errors.expiration_date)
                    }
                    helperText={
                      touched.expiration_date && errors.expiration_date
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    error={touched.supplier_id && Boolean(errors.supplier_id)}
                  >
                    <InputLabel id="supplier-select-label">
                      {props?.t("Supplier")}
                    </InputLabel>
                    <Field
                      as={Select}
                      labelId="supplier-select-label"
                      name="supplier_id"
                      label={props?.t("Supplier ID")}
                    >
                      {suppliers?.map((supplier) => (
                        <MenuItem key={supplier?.id} value={supplier?.id}>
                          {supplier?.trade_name}
                        </MenuItem>
                      ))}
                    </Field>
                    {touched.supplier_id && errors.supplier_id && (
                      <FormHelperText>{errors.supplier_id}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    fullWidth
                    name="note"
                    label={props?.t("Note")}
                    as={TextField}
                    error={touched.note && Boolean(errors.note)}
                    helperText={touched.note && errors.note}
                  />
                </Grid>
              </Grid>
              <Box sx={{ display: "flex", gap: "5px", marginTop: "10px" }}>
                <Button
                  variant="contained"
                  // color={theme.palette.brandColorOne.main}
                  sx={{
                    backgroundColor: theme.palette.brandColorOne.main,
                    color: theme.palette.whiteFont.main,
                  }}
                  type="submit"
                  size="small"
                >
                  {editOptions?.id ? props?.t("Update") : props?.t("SAVE")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => cancelForm()}
                  sx={{
                    marginLeft: "10px",
                    backgroundColor: theme.palette.brandColorOne.main,
                    color: theme.palette.whiteFont.main,
                  }}
                  size="small"
                >
                  {props?.t("Cancel")}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default withTranslation()(AddIncomingGoodsForm);
