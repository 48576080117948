import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import {
    TextField,
    Button,
    Grid,
    Box,
    Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { api } from "../axios/api";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AES from "crypto-js/aes";
import { withTranslation } from "react-i18next";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
});

const AddUserForm = (props) => {
  let { editOptions, setShowAddForm } = props;
  const theme = useTheme();
  const user = useSelector((state) => state.user);

  const [initialValues, setInitialValues] = useState({
    first_name: "",
    email: "",
    password: "",
    last_name: "",
  });

  useEffect(() => {
    console.log("user at add contact", user);
  }, [user]);

  const onSubmit = (values, { resetForm }) => {
    console.log(values);

    var encPass = AES.encrypt(
      values.password,
      process.env.REACT_APP_CRYPTO_JS_KEY
    ).toString();

    // return;

    let body = {
      first_name: values.first_name,
      email_address: values.email,
      password: encPass,
      last_name: values.last_name,
    };
    api
      .post("/addUserByAdmin", body)
      .then((res) => {
        console.log("response from posting content", res?.data);
        toast.success("contact added successfully!");
        resetForm();
        setTimeout(() => {
          setShowAddForm(false);
        }, 2000);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error("error in adding contact");
      });
  };
  const cancelForm = () => {
    setShowAddForm(false);
  };
  return (
    <Box sx={{ padding: "30px 0px" }}>
      <ToastContainer />
      {/* <Typography
        variant="h6"
        sx={{
          color: theme.palette.heading.main,
          fontSize: "1.3rem",
          fontWeight: "600",
        }}
      >
        Add Contact
      </Typography> */}
      <Box
        sx={{
          backgroundColor: theme.palette.background.main,
          padding: "20px",
          borderRadius: "5px",
          marginTop: "20px",
          maxWidth: "700px",
        }}
      >
        <Typography
          variant="body2"
          sx={{ margin: "10px 0 30px 0", color: theme.palette.para.main }}
        >
          {props?.t("Fill up the form with user data")}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Field
                    name="first_name"
                    as={TextField}
                    label={props?.t("First Name")}
                    fullWidth
                    error={touched.first_name && Boolean(errors.first_name)}
                    helperText={touched.first_name && errors.first_name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="last_name"
                    as={TextField}
                    label={props?.t("Last Name")}
                    fullWidth
                    error={touched.last_name && Boolean(errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                  />
                </Grid>

                <Grid item xs={12} sm={editOptions?.id ? 12 : 6}>
                  <Field
                    name="email"
                    as={TextField}
                    label={props?.t("Email")}
                    fullWidth
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Grid>
                {!editOptions?.id && (
                  <Grid item xs={12} sm={6}>
                    <Field
                      name="password"
                      as={TextField}
                      label={props?.t("Password")}
                      fullWidth
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    // color={theme.palette.primary.main}
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.whiteFont.main,
                    }}
                    type="submit"
                    size="small"
                  >
                    {props?.t("Submit")}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => cancelForm()}
                    sx={{
                      marginLeft: "10px",
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.whiteFont.main,
                    }}
                    size="small"
                  >
                    {props?.t("Cancel")}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default withTranslation()(AddUserForm);
