export const setUser = (user) => (dispatch) => {
  dispatch({
    type: "SET_USER",
    payload: user,
  });
  localStorage.setItem("lucuma_user", JSON.stringify(user));
};
export const setToken = (token) => (dispatch) => {
  dispatch({
    type: "SET_Token",
    payload: token,
  });
  localStorage.setItem("lucuma_token", JSON.stringify(token));
};
export const setViewMode = (mode) => (dispatch) => {
  dispatch({
    type: "SET_VIEW_MODE",
    payload: mode,
  });
  localStorage.setItem("lucuma_view_mode", JSON.stringify(mode));
};

export const TRIGGER_REFRESH = 'TRIGGER_REFRESH';

export const triggerRefresh = () => ({
  type: TRIGGER_REFRESH,
});

