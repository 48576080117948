import React, { useState, useEffect } from "react";
import {
    Typography,
    IconButton,
    TextField,
    Button,
    Tabs,
    Tab,
    Box, FormControl, Select, MenuItem,
} from "@mui/material";
import {LockOutlined, Settings, SettingsOutlined} from "@mui/icons-material";
import AES from "crypto-js/aes";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api } from "../axios/api";
import { useTheme } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import {setUser, setToken, triggerRefresh} from "../actions/userActions";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const Account = () => {
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showResetForm, setShowResetForm] = useState(true);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedBackend, setSelectedBackend] = useState('aws');
  const user = useSelector((state) => state.user);
  useEffect(() => {
    console.log("user", user);
    setSelectedBackend(user.selectedBackend ?? 'aws');
  }, [user]);
  const logout = () => {
    dispatch(setUser({}));
    dispatch(setToken(""));
    navigate("/");
    localStorage.setItem("user", null);
    localStorage.setItem("token", null);
  };
    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };
  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Current Password: ", currentPassword);
    console.log("New Password: ", newPassword);
    console.log("Confirm Password: ", confirmPassword);
    var currPassEnc = AES.encrypt(
      currentPassword,
      process.env.REACT_APP_CRYPTO_JS_KEY
    ).toString();
    var newPassEnc = AES.encrypt(
      newPassword,
      process.env.REACT_APP_CRYPTO_JS_KEY
    ).toString();
    let body = {
      email: user?.email_address,
      currentPassword: currPassEnc,
      newPassword: newPassEnc,
    };
    console.log("body", body);
    await api
      .post("/resetpassword", body)
      .then((res) => {
        console.log("response from resetting password", res.data);
        toast.success("Password updated successfully!");
        logout();
      })
      .catch((err) => {
        console.log("error while resetting password", err);
        toast.error("unable to reset password");
      });
    setConfirmPassword("");
    setNewPassword("");
    setCurrentPassword("");

    setShowResetForm(false);
  };

  const handleResetClick = () => {
    setShowResetForm(true);
  };

  const handleCancelClick = () => {
    setShowResetForm(false);
  };

  const validateForm = () => {
    return (
      currentPassword.length >= 8 &&
      newPassword.length >= 8 &&
      confirmPassword === newPassword
    );
  };

    const handleVersionChange = async (event) => {
        const selectedVersion = event.target.value;
        setSelectedBackend(selectedVersion);
        console.log('Selected backend version:', selectedVersion);
        await api
            .post("/updateUserBackend/"+user.id+"/"+selectedVersion, {})
            .then((res) => {
                console.log("Extraction engine changed successfully", res.data);
                toast.success("Extraction engine changed successfully!");
                dispatch(triggerRefresh());
            })
            .catch((err) => {
                console.log("error while changing", err);
                toast.error("Cannot change backend engine");
                dispatch(triggerRefresh());
            });
    };

  return (
    <Box style={{ padding: "1rem" }}>
      <ToastContainer />
      <Typography
        variant="h6"
        fontWeight="bold"
        color={theme.palette.heading.main}
      >
        Account Settings
      </Typography>

      {/* {showResetForm ? ( */}
      <Box
        style={{
          marginTop: "2rem",
          background: theme.palette.background.main,
          padding: "30px",
        }}
      >
          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Settings Tabs">
              <Tab label="Reset Password" />
              {/*<Tab label="Additional Settings" />*/}
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
        <Box mt={2} display="flex" alignItems="center">
          <Box mr={1}>
            <IconButton
              // onClick={handleResetClick}
              sx={{
                backgroundColor: theme.palette.primary.main,
                padding: "7px",
              }}
            >
              <LockOutlined
                sx={{
                  width: "25px",
                  height: "25px",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  color: "white",
                  boxShadow: `0px 4px 10px -6px
                  rgba(0,0,0,0.75)`,
                }}
              />
            </IconButton>
          </Box>
          <Typography>Reset Password</Typography>
        </Box>
        <form onSubmit={handleSubmit} style={{ maxWidth: "300px" }}>
          <TextField
            label="Current Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={currentPassword}
            onChange={(event) => setCurrentPassword(event.target.value)}
            required
          />
          <TextField
            label="New Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newPassword}
            onChange={(event) => setNewPassword(event.target.value)}
            required
          />
          <TextField
            label="Confirm Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
            required
          />
          <Button
            variant="contained"
            type="submit"
            disabled={!validateForm()}
            sx={{ marginRight: "1rem" }}
          >
            Save
          </Button>
          <Button variant="outlined" onClick={handleCancelClick}>
            Cancel
          </Button>
        </form>
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
              <Box>
                  <FormControl  style={{ maxWidth: "300px" }}>
                      <Box mt={2} display="flex" alignItems="center" style={{
                          paddingBottom: "16px",
                      }}>
                          <Box mr={1}>
                              <IconButton
                                  // onClick={handleResetClick}
                                  sx={{
                                      backgroundColor: theme.palette.primary.main,
                                      padding: "7px",
                                  }}
                              >
                                  <SettingsOutlined
                                      sx={{
                                          width: "25px",
                                          height: "25px",
                                          borderRadius: "50%",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          color: "white",
                                          boxShadow: `0px 4px 10px -6px
                  rgba(0,0,0,0.75)`,
                                      }}
                                  />
                              </IconButton>
                          </Box>
                          <Typography>
                              Select Extracting Backend
                          </Typography>
                      </Box>

                      <Select value={selectedBackend ?? "aws"} onChange={handleVersionChange}>
                          <MenuItem value="aws">AWS Textract</MenuItem>
                          <MenuItem value="gpt">OpenAI ChatGPT</MenuItem>
                      </Select>
                  </FormControl>
              </Box>
          </TabPanel>
      </Box>
    </Box>
  );
};

export default Account;
