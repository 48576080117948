import "./App.css";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import RecoverPassword from "./pages/RecoverPassword";
import { PrivateRoute } from "./Routing/PrivateRoute";
import VerifyAccount from "./pages/VerifyAccount";

import { ThemeProvider } from "@mui/material/styles";
import lightTheme from "./Themes/lightTheme"; // Import the shared colors
import darkTheme from "./Themes/darkTheme"; // Import the shared colors
import { useSelector } from "react-redux";

function App() {
  const mode = useSelector((state) => state.viewMode);
  return (
    <ThemeProvider theme={mode === "light" ? lightTheme : darkTheme}>
      <Box className="App">
        <Router>
          <Routes>
            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/reset-password/:userId/:uniqueString"
              element={<RecoverPassword />}
            />
            <Route
              path="/verify-account/:userId/:uniqueString"
              element={<VerifyAccount />}
            />
            <Route path="/" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </Box>
    </ThemeProvider>
  );
}

const NotFound = () => {
  return <Box>404</Box>;
};
export default App;
