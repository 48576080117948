import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        color: "#000", // Set the color of all buttons to black
      },
    },
  },
  palette: {
    mode: "dark",
    primary: {
      // main: "#5369f8",
      main: "#57C5B6",
    },
    secondary: {
      main: "#2C3333",
    },
    background: {
      main: "#101010",
    },
    tableBg: {
      main: "#00000099",
    },
    heading: {
      main: "#fff",
    },
    para: {
      main: "#fff",
    },
    tableHeader: {
      main: "#fff",
    },
    tableValue: {
      main: "#fff",
    },
    menuHeading: {
      main: "#fff",
    },
    menuItem: {
      main: "rgba(255, 255, 255, 0.7)",
    },
    black: {
      main: "#000",
    },
    iconColor: {
      main: "#6c757d",
    },

    selectedMenuFont: {
      // main: "#5f63f2",
      main: "#159895",
    },
    selectedMenuBack: {
      // main: "#5f63f205",
      main: "#1A5F7A05",
    },
    whiteFont: {
      main: "#fff",
    },
    brandColorOne: {
      main: "#04829d",
    },
    brandColorTwo: {
      main: "#8b0c14",
    },
    brandColorThree: {
      main: "#e8f7f8",
    },
    brandColorFour: {
      main: "#589fab",
    },
    brandColorFive: {
      main: "#33a9cc",
    },
    brandColorSix: {
      main: "#78c5d0",
    },
    brandColorSeven: {
      main: "#761720",
    },
    brandColorEight: {
      main: "#1c6474",
    },
    brandColorNine: {
      main: "#1c6c6c",
    },
  },
  typography: {
    // fontFamily: "IBM Plex Sans, sans-serif",
    fontFamily: "Inter, sans-serif",
  },
});

export default theme;
